import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.css']
})
export class AdminManagementComponent implements OnInit {
  pageNumber: number = 1;
  beDisable: boolean = true;
  userForm: FormGroup;
  totalItems: any;
  userID: any;
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  ipAddress: any;
  userListlength: any;

  constructor(private router: Router, public service: MainService, public http: HttpClient) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
       
        this.ipAddress = data.ip
       
      })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    })
    // this.onDateChanged();
    
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;


    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/


  // Get List of User
 
  // Apply Filter
  

  // Delete / Block Function
 
  //User Details
  userdetail(userId) {
    this.router.navigate(['view-admin/' + userId])
  }
  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {

      dataArr.push({
        "S.No.": ind + 1,
        "User ID": element.userId ? element.userId : '',
        "User Name": element.firstName + '' + element.lastName,
        "Email": element.email ? element.email : 'N/A',
        "Role": 'STAFF',
        "Mobile Number": element.phoneNo ? element.phoneNo : 'N/A',
        "Status": element.userStatus,
        "Last Logged In": element.lastLoginTime ? element.lastLoginTime.slice(0, 10) : 'N/A',
        "Created Date & Time": element.createdTime ? element.createdTime.slice(0, 10) : 'N/A',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Staff Management List');
  }

  // Delete User
  deleteFunction() {
    $('#deleteModal').modal('hide')
    let data = {
      "ipAddress": this.ipAddress.ip,
      "primaryIdCommonPerRequest": this.userID
    }
   
    
  }

  performAction() {
    $('#active').modal('hide')
    this.service.showSpinner();

    var url = 'account/admin/user-management/user-status?ipAddress=' + (this.ipAddress) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userID) + '&userStatus=' + ('ACTIVE');
   /*  this.service.post(url, '').subscribe((res) => {
     
      this.service.toasterSucc("Successfully activated.")
      this.service.hideSpinner();
      this.getUserList();
    }) */
  }

  performActionActive() {
    $('#block').modal('hide')
    this.service.showSpinner();
    var url = 'account/admin/user-management/user-status?ipAddress=' + (this.ipAddress) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userID) + '&userStatus=' + ('BLOCK');
   /*  this.service.post(url, '').subscribe((res) => { 
      this.service.toasterSucc("Successfully deactivated.")
      this.service.hideSpinner();
      this.getUserList();
    }) */
  }

  addAdmin() {
    this.router.navigate(['/add-admin'])
  }

  reset() {
    this.userForm.reset();
    /* this.getUserList(); */
  }

  search() {
    this.service.showSpinner();
    if (this.userForm.value.enddate) {
      let data = {
        "fromDate": this.userForm.value.startdate.epoc * 1000,
        "page": "0",
        "pageSize": "10",
        "toDate": (this.userForm.value.enddate.epoc * 1000) + (1000 * 60 * 60 * 24)
      }
     
      var url = "account/admin/user-management/search-and-filter-staff";
   
    }
    else if (this.userForm.value.searchText) {
      let data = {
        "page": "0",
        "pageSize": "10",
        "search": this.userForm.value.searchText,
      }
      
      var url = "account/admin/user-management/search-and-filter-staff";
      
    }
  }

  goUser() {
    this.router.navigate(['/user-management'])
  }
  editAdmin(id) {
    this.router.navigate(['/edit-admin'], {queryParams:{id}})
  }
}
