import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
  currUrl: string;
  isLoggedIn: boolean;
  userDetail: any = {};
  userID: any;
  previlage: any = [];
  getperm: any;
  getrole: any;
  getpermission: any;
  dashboardflag: boolean = false;
  staticflag: boolean = false;
  staticusermgmt: boolean = false;
  staticfeemgmt: boolean = false;
  staticwalletmgmt: boolean = false;
  statickycmgmt: boolean = false;
  statichotcoldmgmt: boolean = false;
  usermanagementflag: boolean = false;
  permissionList: any;
  logsmgmt: boolean = false;
  ticketmgmt: boolean = false;
  trademgmt: boolean = false;
  disputemgmt: boolean = false;
  bankmgmt: boolean = false;
  feemgmt: boolean = false;
  role: string;
  flag: boolean = false;
  adminmgmt: boolean = false;
  settingmgmt: boolean = false;
  advertisementmgmt: boolean = false;

  constructor(private routes: Router, public service: MainService) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        console.log("currUrl",this.currUrl)
        if (this.service.isLoggedIn()) {
          if ((this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == ``)) {
            this.routes.navigate([`/dashboard`])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl.includes(`reset-password`) || this.currUrl == ``)) {
            this.routes.navigate([`/login`])
          }
        }
      }
    })
  }

  ngOnInit() {
    // login or when profile edit
   

    // when page refresh
    if (this.service.isLoggedIn()) {
      this.isLoggedIn = true;
      this.myProfile();
    } else {
      this.isLoggedIn = false;
    }
  }


  // get profile of admin and permissions
  myProfile(){
    let url = "admin/getProfile"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.userDetail = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
       this.service.errorToast(res.responseMessage)
       this.service.hideSpinner()
      }
    },(err)=>{
     this.service.errorToast(err)
 
     this.service.hideSpinner()
    })
   }

  // open logout modal
  logoutModalOpen() {
    $('#signout_modal').modal('show');
  }

  // logout
  onLogout() {
    $('#signout_modal').modal('hide');
    this.service.logout()
  }

}
