<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
              <h4><i class="fa fa-question" aria-hidden="true"></i>&nbsp;
                  FAQs</h4>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div >
                                <form >
                                    <div class="row d-flex justify-content-end mb20 " >
                                        <div class="col-md-4">
                                            <div class="admin-btn text-right">
                                              <button type="btn btn-theme" class="btn  btn-theme ml-2 Go_btn"
                                                 routerLink="/add-faq">Add
                                                  </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th class="Sorting-img"> S.No.</th>
                                        <th class="Sorting-img">Question</th>
                                        <th class="Sorting-img">Answer</th>
                                        <th class="action_td_btn3 Sorting-img">Actions</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr class="text-center"
                                        *ngFor="let item of faqList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: total}; let i = index">
                                        <td> {{itemPerPage * (currentPage -1)+ i +1 }}</td>
                                        <td class="content">{{item?.question}}</td>
                                        <td class="content">{{item?.answer}}</td>
                                        <!-- <td class="justify-content-center d-flex">
                                            <button class="btn btn-info  bt-margin" title="view"
                                            (click)="viewUser(item?.faqId)">
                                            <i class="fa fa-eye" style="color:white"></i></button>
                                            <button class="btn btn-info  bt-margin" (click)="editFaq(item?.faqId)"><i
                                                    class="fa fa-edit" style="color:white"></i></button>
                                            <button class="btn btn-danger  bt-margin" title="delete"
                                                (click)="deleteFaqModal(item?.faqId)"><i class="fa fa-trash"
                                                    style="color:white"></i></button>
                                        </td> -->
                                        <td class="action_td_btn3" style="cursor: pointer">
                                         
                                            <a><em class="fa fa-eye" (click)="viewUser(item._id)" ></em></a>
                                            <a><em class="fa fa-edit"
                                                  (click)="editFaq(item._id)"  ></em></a>
                                            <a><em class="fa fa-trash"
                                                (click)="deleteFaqModal(item?._id)"     ></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="faqList.length==0">
                                        <td class="table-no-record" colspan="4">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-right" >
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </div>
  
  <!-- delete_modal Start -->
  <div class="modal fade global-modal reset-modal" id="delete">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
              <div class="modal-header d-block text-center modal-header-custm">
                  <h5 class="modal-title d-inline-block">Delete FAQs?</h5>
              </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this faq?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme ml-2" (click)="deleteFaq()">Yes</button>
                                    <button type="button" class="btn  btn-theme ml-2" data-dismiss="modal" (click)="hideModal()">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  </div>
  <!-- delete_modal End -->
  
