import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-dispute-trade-details',
  templateUrl: './dispute-trade-details.component.html',
  styleUrls: ['./dispute-trade-details.component.css']
})
export class DisputeTradeDetailsComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef
  @ViewChild('scrollMeAdminChat', { static: true }) private myScrollContainerAdminChat: ElementRef
  supportChatArr : any = []
  tradeId: any;
  obj: any = {}
  tradeDetails: any;
  toEmail: string;
  adminData: any;
  adminEmail: any;
  currentDate: Date;
  buyerSeller: any;
  fkUserId: any;
  partnerId: any;
  takeAction: boolean;
  takeDipute: boolean = false;
  recommendedAction: boolean;
  remarkForm: FormGroup;
  chatHistory: any = [];
  disputeId: any;
  adminId: any;
  toUserId: any;
  chatInitAndSubscribe: boolean = false;
  timerEvidence: any = '';
  timerForEvidence: any;
  timerArray: any[] = [3, 6, 12, 24, 48, 72, 96, 120, 144, 168];
  imageSendInChat: any;

  // pagination
  itemsPerPageDispute: any = 5;
  currentPageDispute: any = 1;
  totalItemsDispute: any;
  disputeHistoryArray: any[] = [];
  itemsPerPageTrade: any = 5;
  currentPageTrade: any = 1;
  totalItemsTrade: any;
  tradeHistoryArray: any[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: MainService) {
    this.activatedRoute.params.subscribe((res) => {
   
      if (res.id) {
        this.tradeId = res.id
      }
    })
  }

  ngOnInit() {
    this.adminId = localStorage.getItem('userId')
    this.currentDate = new Date();
    this.getTradeDetails();
    this.remarkFormValidation();
  }

  // scroll chat of buyer and seller container
  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 10)
  }

  // get trade details
  getTradeDetails() {
   
  }

  // get chat history of buyer and seller
  getChatHistory() {
   
  }

  // openDispute() {
  //   this.service.showSpinner();
  //   this.service.get(`p2p-exchange/after-press-dispute-button?disputeStatus=Raised&tradeId=${this.tradeId}`).subscribe((res) => {
  //  
  //     this.service.hideSpinner()
  //     if (res['status'] == 200) {
  //       this.service.toasterSucc(res['message'])
  //       this.router.navigate(['/dispute-management'])
  //     } else {
  //       this.service.toasterErr(res['message'])
  //     }
  //   }, err => {
  //     this.service.toasterErr(err['message'])
  //     this.service.hideSpinner()
  //   })
  // }

  // take dispute
  takeDisputeByAdmin() {
    this.takeDipute = true
  }




  /**
   * recommended action taken by admin
   * remark will show whatever observation done by admin/staff
   */
  recommendedActionByAdmin() {
    $('#recommendedActionModal').modal('show')
  }

  remarkFormValidation() {
    this.remarkForm = new FormGroup({
      'remark': new FormControl('', Validators.required)
    })
  }

  recommendedActionByAdminSave() {
    let url = `p2p-exchange/admin/recommendation?disputeId=${this.disputeId}&recommendation=${this.remarkForm.value.remark}`
  
  }




  /**
   * admin select the user from whome he want to chat and ask for evidence
   * @param event 
   */
  // select user for chat
  selectUserForChat(event) {
   
  }

  // get chat history of admin and buyer/seller
  chatHistoryAdminAndUser() {
    
  }

  // scroll chat to bottom of container
  scrollToBottomAdminChat() {
    setTimeout(() => {
      try {
        this.myScrollContainerAdminChat.nativeElement.scrollTop = this.myScrollContainerAdminChat.nativeElement.scrollHeight
      } catch (err) { }
    }, 10);
  }

  // send chat
  sendChat() {
  
    
  }

  // ask for evidence timer
  timerForEvidenceAsk(event) {
    this.timerForEvidence = event.target.value
    if (this.timerForEvidence == '') {
      return
    }
    this.obj.chat = 'Please submit your evidence within ' + this.timerForEvidence + ' hour'
  }

  // send image in chat
  handleFileInput(event) {
    
  }

  sendFormData(fileData) {
    
  }



  /**
   * action taken by admin/staff after the investigation based on chat and evidence
   */
  // action taken by admin/staff
  takeActionByAdmin() {
    $('#takeActionModal').modal('show')
  }
  releaseForDetailsModal() {
    $('#takeActionModal').modal('hide')
    $('#releaseEscrowModal').modal('show')
  }

  releaseForDetails() {
  
  }


  // dispute history table data
  getDisputeHistory() {
  
  }

  paginationDispute(event) {
    this.currentPageDispute = event
    this.getDisputeHistory()
  }

  // trade history table data
  getTradeHistory() {
    let url = `p2p-exchange/admin/get-trade-history?page=${this.currentPageTrade - 1}&pageSize=${this.itemsPerPageTrade}&buyerId=${this.fkUserId}&sellerId=${this.partnerId}`
  
  }

  paginationTrade(event) {
    this.currentPageTrade = event
    this.getTradeHistory()
  }
}

