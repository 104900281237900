<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title">Trade Details</h1>
  </div>
  <div class="content-section" style="    margin-bottom: 50px;
  ">
    <div class="outer-box">
      <div class="global-table no-radius p0">
        <div class="tab-content1">
          <div class="tab-pane1">
            <div class="row">
              <div class="col-md-6">
                <!-- chat section -->
                <div class="chat-box-main">
                  <div class="chat-box">
                    <div class="chat-head">
                      <h4>Chat</h4>
                    </div>
                    <div class="chat-div" #scrollMe style="overflow-y: auto; height: 350px;">
                      <div *ngFor="let item of chatHistory">
                        <div *ngIf="tradeDetails.fkUserId != item.fromUserId" class="left-chat">

                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender">
                            <div class="chat">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="tradeDetails.fkUserId == item.fromUserId" class="right-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender" style="order: 1;">
                            <div class="chat2" style="order: 0;">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                <!-- end chat section -->
              </div>
              <div class="col-md-6">
                <div class="sec_head_new">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Trade Type </label>
                        <div class="col-sm-5">
                          <label class=" col-form-label">{{tradeDetails?.type || '---'}}</label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Advertisement</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradingPartner || '---'}}</label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Ad Creator</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradingPartner || '---'}}</label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Status</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.statusType || '---'}}</label>

                        </div>
                      </div>

                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Amount BTC</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.totalBTC || '---'}} <span
                              *ngIf="tradeDetails?.totalBTC">{{tradeDetails?.cryptoCoin}}</span></label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">For</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradeAmount || '---'}} <span
                              *ngIf="tradeDetails?.tradeAmount">{{tradeDetails?.fiatCoin}}</span> </label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Exchange Rate</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.price || '---'}} <span
                              *ngIf="tradeDetails?.price">{{tradeDetails?.cryptoCoin}}/{{tradeDetails?.fiatCoin}}</span>
                          </label>

                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Payment Method</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.paymentType || '---'}}</label>

                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!-- trade history section -->
            <div class="transaction">
              <h3>Trade History</h3>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered" aria-describedby="trade history table">
                <thead>
                  <tr class="no_wrap_th">
                    <th scope="col">Report Id</th>
                    <th scope="col">Date </th>
                    <th scope="col">Trade Owner Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>

                  <tr
                    *ngFor="let data of tradeList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                    <td>{{data?.tradingId || '---'}}</td>

                    <td>{{(data?.creationTime | date:'medium') || '---'}}</td>
                    <td>{{data?.buyer || '---'}}</td>
                    <td>{{data?.tradeStatus || '---'}}</td>

                  </tr>
                  <tr *ngIf="tradeList?.length==0">
                    <td colspan="4" vertical-align="middle">
                      <div class="no-record">
                        <div class="no-recordin">
                          <h5>No record found</h5>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="custom-pagination mt20 text-right" *ngIf="totalItems > 5">
              <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
            </div>
            <!-- end trade history section -->
          </div>
          <div class="text-center" style="margin-top:20px">
            <button routerLink = "/trade-management" class="btn btn-info">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
