import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-kyc-management',
  templateUrl: './kyc-management.component.html',
  styleUrls: ['./kyc-management.component.css']
})
export class KycManagementComponent implements OnInit {

  searchText:string=''
  pageNumber: number =1;
  status: string;
  kycList: any=[];
  kycData: any={};
  userKycId: any;
  kycDetail: any;
  itemPerPage=15;
  constructor(public service : MainService,public router:Router) { }

  ngOnInit() {
    this.getListOfKyc();
  }
  gotoview(id){

    this.router.navigate(['/kyc-action/id'],{
    queryParams:{id:id}})
    }
  // getStatus Function
  getStatus(event){
    this.status = event.target.value;
    if(this.status == "ALL"){
      this.getListOfKyc()
    }else{
      this.search()
    }
    
  }

  // getListOFKYC Function
  getListOfKyc(){
    let url = "admin/kycList"
    const data = {
     
    }
   this.service.showSpinner()
   this.service.postApi(url,data,1).subscribe((res)=>{
     if(res.responseCode == 200){
       this.kycList = res.result.docs
      this.service.hideSpinner()
      this.service.successToast(res.responseMessage)
     }
     else{
      this.service.hideSpinner()
      this.service.errorToast(res.responseMessage)
     }
   },(err)=>{
    this.service.hideSpinner()
    this.service.errorToast(err)
   })
    
  }

search(){

  let url = "admin/kycList"
    const data = {
      kycStatus : this.status != 'ALL' ? this.status : "",
      search : this.searchText
    }
    this.kycList = []
   this.service.showSpinner()
   this.service.postApi(url,data,1).subscribe((res)=>{
     if(res.responseCode == 200){
       this.kycList = res.result?.docs
      this.service.hideSpinner()
      this.service.successToast(res.responseMessage)
     }
     else{
      this.service.hideSpinner()
      this.service.errorToast(res.responseMessage)
     }
   },(err)=>{
    this.service.hideSpinner()
    this.service.errorToast(err)
   })
}
 reset(){
   this.getListOfKyc()
   this.searchText = ""
   this.status = ""
 }
  
  changePageNumber(page){
    this.pageNumber = page;
    this.getListOfKyc();
  }

 

  

}
