<main class="middle-content">
  <!-- Page Title Start -->
  <div class="page_title_block">
    <h1 class="page_title" style="color:black">Fee Management</h1>
  </div>
  <!-- Page Title End -->
  <div class="content-section">

    <div class="order-view">
      <div class="custom_tabs common-tabs">
        <div class="row mb20 justify-content-center">
          <div class="col-sm-12">
            <ul class="nav nav-tabs d-flex  justify-content-center text-center">
              <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='Fee'}" (click)="selectTab('Fee')"
                  href="javascript:;">Fee Management</a>
              </li>
              <li class="nav-item flex-fill">
                <a class="nav-link active show" [ngClass]="{'active': currTab=='Advertisement'}"
                  (click)="selectTab('Advertisement')" href="javascript:;">Advertisement Fee</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="tab-content">
         

          <!-- fee management content tab -->
          <div class="tab-pane active show" *ngIf="this.currTab == 'Fee'">
            <div class="global-table no-radius p0">
              <div class="table-responsive">
                <div id="table-scroll" class="table-scroll">
                  <div class="table-wrap">
                    <table class="main-table" aria-describedby="">
                      <thead>
                        <th id="" class="text-center">Coin</th>
                        <th id="" class="text-center">Withdraw Fee</th>
                        <th id="" class="text-center">Action</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of feecoinArry ;let i = index">
                          <th id="" class="fixed-side">{{item.coinShortName}}</th>
                          <td>
                            <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.withdrawlFee"
                              placeholder="Withdraw Fee">
                          </td>
                          <td>
                            <a class="btn btn-large  max-WT-200 font-100 btn-green"
                              (click)="updatefeeapi(item.coinShortName,item.withdrawlFee)">Update</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

       

          

          <!-- advertisement tab content -->
          <div class="tab-pane active show" *ngIf="currTab == 'Advertisement'">
            <div class="global-table no-radius p0">
              <div class="table-responsive">
                <div id="table-scroll" class="table-scroll">
                  <div class="table-wrap">
                    <table class="main-table" aria-describedby="">
                      <thead>
                        <th id="" class="text-center">Coin</th>
                        <th id="" class="text-center">Trade Fee</th>
                        <th id="" class="text-center">Action</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of feecoinArry ;let i = index">
                          <th id="" class="fixed-side">{{item.coinShortName}}</th>
                          <td>
                            <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.tradeFee"
                              placeholder="Trade Fee">
                          </td>
                          <td>
                            <a class="btn btn-large  max-WT-200 font-100 btn-green"
                              (click)="updateTradeFee(item.coinShortName,item.tradeFee)">Update</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>



        

      </div>


    </div>
  </div>

</main>