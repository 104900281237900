<main class="middle-content" style="margin-bottom: -20%;">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">VIEW USER</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">

                    <div class="custom_tabs common-tabs">
                        <div class="row mb20 justify-content-center">
                            <div class="col-sm-12">
                                <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                    <li class="nav-item flex-fill" [ngClass]="this.currSubTab=='GENERAL'?  'active' : ''">
                                        <a class="nav-link active show " [ngClass]="{'active': currSubTab=='GENERAL'}"
                                            (click)="selectTab('GENERAL')" href="javascript:;">GENERAL</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show "
                                            [ngClass]="{'active': currSubTab=='WALLET'}"
                                            (click)="selectTab('WALLET')" href="javascript:;">WALLET</a>
                                    </li>
                                    
                                    <li class="nav-item flex-fill"> <a class="nav-link active show "
                                            [ngClass]="{'active': currSubTab=='transcation'}"
                                            (click)="selectTab('transcation')" href="javascript:;">TRANSACTION</a>
                                    </li>
                                    <li class="nav-item flex-fill"> <a class="nav-link active show "
                                            [ngClass]="{'active': currSubTab=='Kyc'}" (click)="selectTab('Kyc')"
                                            href="javascript:;">KYC</a>
                                    </li>

                                  
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currSubTab == 'GENERAL'">

                        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                            <div class="mb40">
                                <div class="add-store-block input-style view-label-align">
                                    <div class="form-group row align-items-baseline">
                                        <label class="col-md-4 textalignright "> Name<span>:</span></label>
                                        <div class="col-md-8 textaligncenter">
                                            <div class="form-group">
                                                <p style="padding: 0px 5px!important;">
                                                    {{userData?.firstName || '--'}} {{userData?.lastName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-baseline">
                                        <label class="col-md-4 textalignright "> Email Id<span>:</span></label>
                                        <div class="col-md-8 textaligncenter">
                                            <div class="form-group">
                                                <p style="padding: 0px 5px!important;">
                                                    {{userData?.email || '--'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row ">
                                        <label class="col-md-4 textalignright ">Mobile Number <span>:</span></label>
                                        <div class="col-md-8 textaligncenter">
                                            <div class="form-group">
                                                <p style="padding: 0px 5px!important;">
                                                    {{userData?.mobileNumber|| '--'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row ">
                                        <label class="col-md-4 textalignright ">Status <span>:</span></label>
                                        <div class="col-md-8 textaligncenter">
                                            <div class="form-group">
                                                <p style="padding: 0px 5px!important;">
                                                    {{userData?.status|| '--'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row ">
                                        <label class="col-md-4 textalignright ">Registration Date & Time <span>:</span></label>
                                        <div class="col-md-8 textaligncenter">
                                            <div class="form-group">
                                                <p style="padding: 0px 5px!important;">
                                                    {{userData?.createdAt|date : 'medium'}}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="text-center mt40 mb40" >
                                        <button type="button" class="btn btn-large  max-WT-150 btn-secondary"
                                            [routerLink]="['/user-management']">Back</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div *ngIf="currSubTab =='transcation' ">
                        <div class="outer-box">

                            <div class="global-table no-radius p0">
                                <div class="tab-content1">
                                 
        
                                    <div class="table-responsive">
                                        <table
                                            class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                            aria-describedby="user management list">
                                            <thead>
                                                <tr class="no_wrap_th text-center">
                                                    <th scope="col" class="Sorting-img">Sno</th>
                                                    <th scope="col" class="Sorting-img">Transaction Id</th>
                                                    <th scope="col" class="Sorting-img">Transaction Status</th>
                                                    <th scope="col" class="Sorting-img">Payment Method</th>
                                                    <th scope="col" class="Sorting-img">Booking Id</th>
                                                    <th scope="col" class="Sorting-img">Service Provider Id</th>
                                                    <th scope="col">Service Booked Id</th>
        
                                                    <th scope="col">createdAt</th>
                                                    <th scope="col"> Status</th>
                                                    <th scope="col">Actions</th>
        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="text-center"
                                                    *ngFor="let i of transactionList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let item = index">
                                                    <td>{{itemPerPage * (currentPage - 1) + item+1}}</td>
                                                    <td class="content">{{i?._id || "--"}}</td>
                                                    <td class="content">{{i?.transactionStatus || "--"}}</td>
                                                    <td class="content">{{i?.paymentMethod || "--"}}</td>
                                                    <td class="content">{{i?.bookingId || "--"}}</td>
                                                    <td class="content">{{i?.serviceProviderId || "--"}}</td>
                                                    <td class="content">{{i?.serviceBookedId || "--"}}</td>
        
                                                    <td class="content">{{(i?.createdAt| date) || "--"}}</td>
                                                    <td class="content">{{i?.status || "--"}}</td>
                                                    <td class="justify-content-center d-flex">
                                                        <button class="btn btn-info ml-2 bt-margin"
                                                           title="view">
                                                            <i class="fa fa-eye" aria-hidden="true"
                                                                style="color:white"></i></button>
                                                     
        
                                                    </td>
                                                </tr>
        
                                            </tbody>
                                            <tbody>
          
                                                <tr *ngIf="transactionList == 0">
                                                    <td class="table-no-record" colspan="10">No Record Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="custom-pagination mt-2 text-align-end">
                                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                        </div>
                       
                    </div>
                    <div *ngIf="currSubTab == 'WALLET' ">
                        <div class="outer-box">
                            <div class="global-table no-radius p0">
                                <div class="tab-content1">
                                    <div class="tab-pane1">
                                        <div class="table-responsive">
                                            <table
                                                class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                                aria-describedby="user management list">
                                                <thead>
                                                    <tr class="no_wrap_th text-center">
                                                        <th scope="col" class="Sorting-img">ID</th>
                                                        <th scope="col" class="Sorting-img">Currency Type</th>
                                                        <th scope="col" class="Sorting-img">Amount</th>
                                                        <th scope="col" class="Sorting-img">Wallet Type</th>
                                                        <th scope="col" class="Sorting-img">Created At</th>
                                                        <th scope="col" class="action_td_btn3 Sorting-img">
                                                            Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="no_wrap_th text-center"
                                                        *ngFor="let i of walletList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let item = index">
                                                        <td>{{i.id}}</td>
                                                        <td>{{i.coinType}}</td>
                                                        <td>{{i.Amount}}</td>
                                                        <td>{{i.WalletType}}</td>
                                                        <td>{{i.DOB}}</td>
                                                        <td class="action_td_btn3" style="cursor: pointer;width: 18%;">
                                                            <button class="btn btn-info ml-2 bt-margin" title="view"
                                                                >
                                                                <em class="fa fa-eye" aria-hidden="true"></em></button>
                                                            <button class="btn btn-danger ml-2 bt-margin" title="delete"
                                                               ><em class="fa fa-trash"
                                                                    aria-hidden="true"></em></button>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="walletList == 0">
                                                        <td class="table-no-record" colspan="6">No Record Found</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <div class="custom-pagination mt-2 text-align-end">
                                                <pagination-controls (pageChange)="pagination($event)">
                                                </pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="modal fade global-modal reset-modal" id="DeleteModal" >
                                <div class="modal-dialog max-WT-500">
                                    <form class="change_password">
                                        <div class="modal-content">
                                            <div class="modal-header d-block text-center modal-header-custm">
                                                <h5 class="modal-title d-inline-block">DELETE</h5>
                                            </div>
                                            <div class="modal-inner-content">
                                                <div class="modal-body">
                                                    <div
                                                        class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                                        <div class="w-100">
                                                            <p>Are you sure you want to delete this user?</p>
                                                            <div class="modal-btn-box">
                                                                <button type="submit" class="btn btn-primary"
                                                                    data-dismiss="modal">Yes</button>
                                                                <button type="button" class="btn btn-secondary ml-2"
                                                                    data-dismiss="modal">No</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> -->
                        </div>



                    </div>
                    <div *ngIf="currSubTab == 'Kyc' ">
                        <div class="outer-box">
                            <div class="global-table no-radius p0">
                                <div class="tab-content1">
                                
                                    <div class="table-responsive">
                                        <table
                                            class="table table-striped table-bordered table-hover kv-grid-table kv-table-wrap"
                                            aria-describedby="section list">
                                            <thead>
                                                <tr class="no_wrap_th text-center">
                                                    <th scope="col" class="Sorting-img">S.No</th>
                                                    
                                                    <th scope="col" class="Sorting-img">User Type</th>
                                                    <th scope="col" class="Sorting-img">Id Number</th>
                                                    <th scope="col" class="Sorting-img">Id Type</th>
                                                    <th scope="col" class="Sorting-img">Kyc Status</th>
                                                    <th scope="col" class="Sorting-img">Created At</th>
                                                    <th scope="col" class="Sorting-img">Status</th>  
                                                    <th scope="col" class="action_td_btn3 Sorting-img">Actions</th>
                                               
                                                
                                             
                                       
                                                 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                <tr class="text-center"
                                                *ngFor="let item of kycList | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems};let i = index">
    
                                                    <td >{{itemPerPage * (currentPage - 1) + i+1}}</td>
                                                    <td class="content">{{item?.userType || '---'}}</td>
                                                    <td class="content">{{item?.idNumber || '---'}}</td>
                                                    <td class="content">{{item?.IdType || '---'}}</td>
                                                    <td class="content">{{item?.kycStatus || '---'}}</td>
                                                    <td class="content">{{(item?.createdAt | date) || '---'}}</td>
                                                
                                                   
                                                    <td class="content">{{item?.status || '---'}}</td>
                                                    <td class="justify-content-center d-flex">
    
                                                        <button class="btn btn-info ml-2 bt-margin"
                                                           > <em class="fa fa-eye"
                                                                aria-hidden="true"></em></button>
    
                                                    </td>
                                                </tr>
                                                
                                                <tr *ngIf="kycList == 0">
                                                    <td class="table-no-record" colspan="8">No Record Found</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="custom-pagination mt-2 text-align-end">
                                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








            </div>
        </div>

    </div>
</main>





<!-- <div *ngIf="view_wallet">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">VIEW WALLET</h1>
        </div>
        <div class="content-section">

            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="shadow p-3 mb-5 bg-white rounded">

                            <div class="table-responsive">

                                <table class="table table-striped table-bordered detail-view"
                                    aria-describedby="user management list">
                                    <tbody>
                                        <tr class="no_wrap_th">
                                            <td style="width: 25%;" class="Sorting-img" style="width: 25%"><strong> ID</strong>
                                            </td>
                                            <td class="content">1</td>
                                        </tr>
                                        <tr class="no_wrap_th">
                                            <td class="Sorting-img"> <strong> Currency Type</strong></td>
                                            <td class="content">USD</td>
                                        </tr>
                                        <tr class="no_wrap_th">
                                            <td class="Sorting-img"> <strong> Amount</strong></td>
                                            <td class="content">15000</td>
                                        </tr>
                                        <tr class="no_wrap_th">
                                            <td class="Sorting-img"> <strong>Wallet Type</strong></td>
                                            <td class="content">Saving</td>
                                        </tr>
                                        <tr class="no_wrap_th">
                                            <td class="Sorting-img"><strong>Created At</strong></td>
                                            <td class="content">01/02/2021</td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                            <div class="text-left mt40 mb40">

                                <button class="btn btn-large  max-WT-150 btn-secondary"
                                    (click)="getwallet()">Back</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </main>
</div> -->

