import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $: any
@Component({
  selector: 'app-referral-details',
  templateUrl: './referral-details.component.html',
  styleUrls: ['./referral-details.component.css']
})
export class ReferralDetailsComponent implements OnInit {

  userDetail: any = {};
  referralId: any;
  constructor(private activatedRoute: ActivatedRoute,
    public service: MainService, public router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      console.log(res);
      if (res.id) {
        // this.toEmail = 'ds123@mailinator.com'
        this.referralId = res.id
        console.log("to user email->", this.referralId)
      }
    })
    this.getReferralDetails();
  }

  getReferralDetails() {
    
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
