import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
declare var $: any
@Component({
  selector: 'app-hotcoldwallet-management',
  templateUrl: './hotcoldwallet-management.component.html',
  styleUrls: ['./hotcoldwallet-management.component.css']
})
export class HotcoldwalletManagementComponent implements OnInit {
  currTab: any;

  walletCheck: any = []
  hotCoin: any;
  addresObj: any = { address: '' }
  coldCoin: any;
  storgeID: any;
  coinListArr: any = []
  dataArr: any = []
  hotcoinname: any;
  hotcoinaddr: any;
  hotbalance: any;
  transferForm: FormGroup
  hotTagId: any;
  coinlist: any = [];
  finalHotWalletArray: any = [];
  finalHotWalletTime: any;
  getAlltransactionsHistoryList: any = [];
  date: any;
  dateTime: any;
  time: any;

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
   
    $("#emailid").on("keypress", function (e) {
      if (e.which === 32 && !this.value.length)
        e.preventDefault();
    });
    this.checkTransferFormValidations();
    this.getCoinWalletList();
    this.getAlltransactionsHistory();

  }
  getAlltransactionsHistory() {
   
  }
  getOtherList() {
    
  }
  getCoiList() {
    
  }
  getCoinWalletList() {
   
  }

  /** to switch between tabs */
  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'HOT') {
      this.coinListArr = [];
      this.getCoinWalletList()
      this.finalHotWalletArray = []
      this.getOtherList()
    } else {
      this.getAlltransactionsHistory()
    }
    // this.getCoinWalletList();
  }
  updateAddressModal(coin) {
    this.coldCoin = coin

    $("#updateAddress1").modal({ backdrop: 'static', keyboard: false })
    this.addresObj.address = ""

  }


  createwalletCold(coin) {

    let data = {

      "coinName": coin,
      "storageType": this.currTab

    }

    //this.service.spinnerShow();
    
  }


  updateAddress(coin) {
    let data = {
      "coinName": coin,
      "coldAddress": 'COLD'
    }
    this.service.showSpinner();
  
  }

  submitAddress() {
   
  }


  generateAddress(coin) {
    this.hotCoin = coin
    let data = {
      "accName": "harold",
      "coinName": this.hotCoin,
      "storageType": this.currTab
    }
   
  }

  updateHotBalance(coin) {
    let data = {
      "coinName": coin,
      "storageType": this.currTab
    }
    
  }

  transferBalance(coin, address, hotbaln, tagid) {
    this.service.showSpinner();
    this.hotcoinname = coin;
    console.log("hotcoinname", this.hotcoinname);
    this.hotcoinaddr = address;
    this.hotbalance = hotbaln;
    this.hotTagId = tagid;
    this.checkTransferFormValidations();
    //console.log("coin name:", coin,address,hotbaln)
    setTimeout(() => {
      $('#transferModal').modal('show');
      this.service.hideSpinner();
    }, 2000);

  }

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'coldAddress': new FormControl(''),
      'transferrAmount': new FormControl(''),
      'transferAmount': new FormControl(''),
      'tag': new FormControl('')
    })
  }


  transferHotToCold() {
    if (this.hotcoinname === 'XRP') {
      let data = {
        "amount": Number(this.transferForm.value.transferrAmount),
        "coinName": this.hotcoinname,
        "toAddress": this.transferForm.value.coldAddress,
        "tag": this.transferForm.value.tag
      }
      //  console.log("transfer data:", data)
    }

  }


  getCoinList() {
    this.coinListArr = []
    this.dataArr = []
    this.service.showSpinner();
    
  }

  getData() {

   
  }
}
