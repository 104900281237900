import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  id: any;
  privacy: any;
  dataa: any=[];
  constructor(public service:MainService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{
    
      this.id= x['id'];
    })
    this.getListCode();
  }

  // Get List Code
  getListCode(){
  
     
   
  }

  // Save Abou Us Functionality
  savePrivacy(){
  
   }

}
