import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-referral-management',
  templateUrl: './referral-management.component.html',
  styleUrls: ['./referral-management.component.css']
})
export class ReferralManagementComponent implements OnInit {
  pageNumber: number = 1;
  beDisable: boolean = true;
  userForm: FormGroup;
  totalItems: any;
  userID: any;
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  ipAddress: any;
  userListlength: any;

  constructor(private router: Router, public service: MainService, public http: HttpClient) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        this.ipAddress = data.ip
        console.log("djfhgdj", this.ipAddress)
      })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    })
    // this.onDateChanged();
    this.getUserList();
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;


    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/


  // Get List of User
  getUserList() {
    // let data = {
    //   "page": 0,
    //   "pageSize": 10,
    // }

    // var url = "account/admin/user-management/filter-user-details?&page=1";
   
  }

  // Apply Filter
  applyFilter(val) {

   
  }

  // Delete / Block Function
  openModal(action, userId) {
    this.userID = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  //User Details
  userdetail(userId) {
    this.router.navigate(['referral-details/' + userId])
  }

  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {
      dataArr.push({
        "Referral ID": element.referralId ? element.referralId : '',
        "Referral By": element.referBy ? element.referBy : '',
        "Referral To": element.referTo ? element.referTo : '',
        "Earned Amount": element.earnAmount ? element.earnAmount : 'N/A',
        "Date & Time": element.createTime ? element.createTime.slice(0, 10) : 'N/A',
        "Status": element.status == true ? 'Active' : 'Inactive',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Referral Management');
  }

  // Delete User
  deleteFunction() {
    $('#deleteModal').modal('hide')

 
  }

  performAction() {
    $('#active').modal('hide')
   
  }

  performActionActive() {
    $('#block').modal('hide')
   
  }

  addAdmin() {
    console.log("add-admin");

    this.router.navigate(['/add-admin'])
  }

  reset() {
    this.userForm.reset();
    this.getUserList();
  }

  search() {
    this.service.showSpinner();
    if (this.userForm.value.enddate) {
      let data = {
        "fromDate": this.userForm.value.startdate.epoc * 1000,
        "page": "0",
        "pageSize": "10",
        "toDate": (this.userForm.value.enddate.epoc * 1000) + (1000 * 60 * 60 * 24)
      }
    
    }
    else if (this.userForm.value.searchText) {
      let data = {
        "page": "0",
        "pageSize": "10",
        "search": this.userForm.value.searchText,
      }
    }
  }

  goUser() {
    this.router.navigate(['/user-management'])
  }
  editAdmin(id) {
    this.router.navigate(['/edit-admin'], { queryParams: { id } })
  }
}

