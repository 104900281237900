import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-view-management',
  templateUrl: './view-management.component.html',
  styleUrls: ['./view-management.component.css']
})
export class ViewManagementComponent implements OnInit {

  searchForm: FormGroup;
  userId : any
  currSubTab : any ="GENERAL"
  //------Pagination Variable-----///
  currentPage : any
  itemPerPage : any = 5
  totalItems : any
  //------Pagination Variable End-----///
  //---General_Tab-Varable---///
  userData : any = []
  //---General_Tab-Varable End---///
  //----TansactionList---///
  transactionList : any = []
  //---Transaction List Ends ---//
    //----Wallet---///
    walletList : any = []
 //----Wallet List End---///
//---Kyc List --- //
kycList : any = []
//---Kyc List End--- //
  
  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    public mainService: MainService
  ) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.userId = res.id;
    });
  }

  ngOnInit() {
    this.searchFormValidation();
    this.selectTab(this.currSubTab)
  }

  
  selectTab(tab){
    this.currSubTab = tab;
    if(this.currSubTab == 'GENERAL'){
      this.getUserDetail()
    }
    else if(this.currSubTab == 'WALLET'){
      this.getUserWallet()
    }
    else if(this.currSubTab == 'transcation'){
      this.getUserTransaction()
    }
  }

  

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
    });
  }
  pagination(event){
    this.currentPage = event
  }
  //------General----//
  getUserDetail(){
    let url = "admin/viewUser/"+this.userId
    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.userData = res.result
        this.mainService.hideSpinner()
        this.mainService.successToast(res.responseMessage)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.mainService.hideSpinner()
        this.mainService.errorToast(err)
    })
  }
//-------General Ends ----//
///------Wallet ----//
getUserWallet(){
  let url = "admin/viewWallet/"+this.userId
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.responseCode == 200){
      this.walletList = res.result
      this.mainService.hideSpinner()
      this.mainService.successToast(res.responseMessage)
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.responseMessage)
    }
  },(err)=>{
    this.mainService.hideSpinner()
      this.mainService.errorToast(err)
  })
}
//----Wallet End-----//
//----Transaction -----//
getUserTransaction(){
  let url = "admin/userTransactionList/"+this.userId
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.responseCode == 200){
      this.transactionList = res.result
      this.mainService.hideSpinner()
      this.mainService.successToast(res.responseMessage)
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.responseMessage)
    }
  },(err)=>{
    this.mainService.hideSpinner()
      this.mainService.errorToast(err)
  })
}
}
