import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ServiceService } from '../service.service';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/app/provider/main.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-dispute-management',
  templateUrl: './dispute-management.component.html',
  styleUrls: ['./dispute-management.component.css']
})
export class DisputeManagementComponent implements OnInit {

  // exchangeArry: any;
  // total: any;
  // p: number = 0;
  // fromDate: any = ''
  // twoDate: any = ''
  // calender: any = { todate: '', formdate: '' }

  // minAge: Date;
  // withdrwal: any = ''

  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  minAge: Date;
  tradeStatus: any = ''
  country: any = ''
  paymentType: any = '';
  type: any = '';
  disputeList: any = [];
  itemsPerPage: number = 15;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];

  assignStaffForm: FormGroup;
  staffId: any;
  disputeId: any;
  staffListArray: any;

  constructor(private router: Router, public service: MainService, private datePipe: DatePipe) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    // this.getUserListwallet(this.p)
    this.getDisputeList()
    this.getCountryList()
    this.assignStaffFormValidations()
    this.getStaffList()
  }

  fromdate() {

    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()

  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // getUserListwallet(p) {

  //   this.service.showSpinner();

  //   // this.service.get('p2p-exchange/get-trade-history-for-admin?pageSize=' + '10' + '&page=' + this.p + '&tradeStatus=' + 'DISPUTE'  + '&fromDate=' + this.fromDate + '&toDate=' + this.twoDate).subscribe((res) => {
  //     this.service.get('p2p-exchange/get-trade-history-for-admin?pageSize=' + '10' + '&page=' + this.p + '&tradeStatus=' + 'DISPUTE').subscribe((res) => {

  //       this.service.hideSpinner();
  //       if (res['status'] == 200) {
  //           this.exchangeArry = res['data'].RESULT_LIST
  //           this.total = res['totalCount'];
  //       }

  //       else {
  //           this.service.toasterErr(res['message']);
  //           this.service.hideSpinner();
  //       }
  //   }, (err) => {
  //       this.service.hideSpinner();
  //   })
  // }

// // ttps://p2p-java.mobiloitte.com/p2p-exchange/admin/search-and-filters-trade-list?page=0&pageSize=5&tradeStatus=DISPUTE
  // get dispute list
  //https://p2p-java.mobiloitte.com/p2p-exchange/admin/get-trade-history?page=0&pageSize=5&buyerId=3&sellerId=4
  getDisputeList() {
    
  }

  // search
  search() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.getDisputeList()
    } else {

    }
  }

  // reset search
  reset() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.tradeStatus = ''
      this.country = ''
      this.paymentType = '';
      this.type = '';
      this.calender = { todate: '', fromdate: '' }
      this.twoDate = ''
      this.fromDate = ''
      this.getDisputeList()
    }
  }

  // pagination
  pagination(page) {
    this.currentPage = page;
    this.getDisputeList()
  }

  // get country list
  getCountryList() {
   
  }

  // nevigate to trade details
  navigate(tradeId) {
    this.router.navigate(['/dispute-trade-details', tradeId])
  }

  exportAsXLXS() {
    let dataArr = [];
    this.disputeList.forEach((element, ind) => {
      dataArr.push({
        'Trade ID': element.tradeId ? element.tradeId : 'N/A',
        'Dispute ID': element.disputeId ? element.disputeId : 'N/A',
        'Buyer': element.buyer ? element.buyer : 'N/A',
        'Seller': element.seller ? element.seller : 'N/A',
        'Trade Date': element.creationTime ? this.datePipe.transform(element.creationTime) : 'N/A',
        'Dispute Date': element.disputeTime ? this.datePipe.transform(element.disputeTime) : 'N/A',
        'Dispute Status': element.disputeStatus ? element.disputeStatus : 'N/A',
        'Trade Amount': element.tradeAmount ? element.tradeAmount : 'N/A',
        'Staff Incharge': element.staffName ? element.staffName : 'N/A'

      })
    })
    this.service.exportAsExcelFile(dataArr, 'Dispute Management List')
  }




  // -------------- get staff list --------------------- //
  getStaffList() {
    let data = {
      "page": "0",
      "pageSize": "10",
      "role": "SUBADMIN",
    }

  }

  assignStaffFormValidations() {
    this.assignStaffForm = new FormGroup({
      'staffName': new FormControl('', Validators.required),
    })
  }

  // --------------- assign dispute modal ----------------- //
  assignStaffModalOpen(disputeId, staffId) {
    this.service.showSpinner()
    this.assignStaffFormValidations();
    this.disputeId = disputeId
    // this.staffId = staffId
    this.assignStaffForm.patchValue({
      'staffName': staffId ? staffId : ''
    })
    setTimeout(() => {
      $('#assignStaffModal').modal('show');
      this.service.hideSpinner();
    }, 500);
  }

  /**
   * assign staff
   * @param staffId as userId
   * @param disputeId
   */
  // assign staff
  assignStaff() {
    let url = `p2p-exchange/admin/assign-staff-for-dispute?disputeId=${this.disputeId}&staffId=${this.assignStaffForm.value.staffName}`
    let data = {
      // 'staffId': this.staffId,
      // 'disputeId': this.disputeId
    }
 
  }
  copyToClipboard(item) {

    this.service.successToast('copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
    e.clipboardData.setData('text/plain', (item));
    e.preventDefault();
    document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    }

}
