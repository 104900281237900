import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IMyDpOptions, IMyDateModel } from "mydatepicker";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MainService } from "src/app/provider/main.service";
declare var $: any;

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.css"],
})
export class UserManagementComponent implements OnInit {
  userForm: FormGroup;
  userId : any
  userList: any = [];

  // pagination
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems: number;




  // search


  userStatus : any 
  constructor(private router: Router, public service: MainService) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      startdate: new FormControl("", Validators.required),
      enddate: new FormControl("", Validators.required),
      searchText: new FormControl(""),
      sedan: new FormControl(""),
      hatch: new FormControl(""),
      suv: new FormControl(""),
    });
    // this.onDateChanged();
    this.getUserList();
    this.getCountryList();
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
  };
  public toDate: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 },
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.myDatePickerOptions = copy1;
  }

  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  public onChange(event: IMyDateModel) {}

  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/

  // get country list
  getCountryList() {}

  // -------------- Get List of User -------------------- //
  getUserList() {
    let url = "admin/userList";
    const data = {
      page: this.currentPage,
      limit: this.itemsPerPage,
    };
    this.service.showSpinner();
    this.service.postApi(url, data, 1).subscribe(
      (res) => {
        if (res.responseCode == 200) {
          this.userList = res.result.docs;
          this.service.hideSpinner();
          this.service.successToast(res.responseMessage);
        } else {
          this.service.hideSpinner();
          this.service.errorToast(res.responseMessage);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.errorToast(err);
      }
    );
  }

  // search

  // reset search
  reset() {}

  // pagination
  pagination(page) {
    this.currentPage = page;
    console.log(this.currentPage);
    this.getUserList();
  }

  // Apply Filter
  applyFilter(val) {}

  // Delete / Block Function
  
  openDleteModal(id){
    this.userId =  id 
    $('#deleteModal').modal('show');
  }
  openBlockModal(val,id){
    this.userStatus = val
    this.userId =  id 
    $('#block').modal('show');
  }
  
  userdetails(data) {
    console.log("fgsdgsdf", data);
    this.router.navigate(["/view-user/"], { queryParams: { id: data } });
  }

  walletdetail(userId) {
    this.router.navigate(["walletdetails/" + userId]);
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {
      dataArr.push({
        "Sl no": ind + 1,
        "User ID": element.userId ? element.userId : "",
        "User Name":
          element.firstName + "" + element.lastName ? element.lastName : "",
        Email: element.email ? element.email : "N/A",
        "Mobile Number": element.phoneNo ? element.phoneNo : "N/A",
        Status: element.userStatus == true ? "Active" : "Inactive",
        "Registration Date & Time": element.createTime
          ? element.createTime.slice(0, 10)
          : "N/A",
      });
    });
    this.service.exportAsExcelFile(dataArr, "User list");
  }

  // Delete User
  deleteUser(){
    let url = "admin/deleteUser/"+this.userId
    this.service.showSpinner()
    this.service.deleteApi(url,{},1).subscribe((res)=>{
      if (res.responseCode == 200) {
        
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
        $('#deleteModal').modal('hide');
        this.getUserList()
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    },(err)=>{
      this.service.hideSpinner();
      this.service.errorToast(err);
    })
  }
  blockUser(){
    let url = "admin/blockUnblockUser"
    const data = {
      "userId": this.userId
    }
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res)=>{
      if (res.responseCode == 200) {
        
        this.service.hideSpinner();
        this.service.successToast(res.responseMessage);
        $('#block').modal('hide');
        this.getUserList()
      } else {
        this.service.hideSpinner();
        this.service.errorToast(res.responseMessage);
      }
    },(err)=>{
      this.service.hideSpinner();
      this.service.errorToast(err);
    })
  }

  performAction() {}

  // ---------------- navigate to admin management ---------------- //
  goAdmin() {
    this.router.navigate(["/admin-management"]);
  }
}
