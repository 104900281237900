import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-kyc-action-page',
  templateUrl: './kyc-action-page.component.html',
  styleUrls: ['./kyc-action-page.component.css']
})
export class KycActionPageComponent implements OnInit {
  userKycId : any
  kycData : any  = []
  rejectKycReason : any = ""

  constructor(public routes: ActivatedRoute, public service: MainService, private router: Router) {
    this.routes.queryParams.subscribe(x => {

      this.userKycId = x.id;
      
    })
   }

  ngOnInit() {
   
    this.getParticularKycDetail()
  }

  // Get Particular KYC Detail
  getParticularKycDetail() {
    let url = "admin/viewKyc/"+this.userKycId
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode){
        this.kycData = res.result
        this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.successToast(err)
    })
  }

  // Approve Document Function 
  openApproveModal() {
   
    $('#approveModal').modal('show')
  }

  approveDocFunc() {
    
    
  }

  // Reject Document Function
  openRejectModal(docId) {
   
    $('#rejectModal').modal('show')

  }

  //User Details
  userdetail(userId) {

    this.router.navigate(['document-details/' + userId])
  }

  rejectDocFunc() {
   
  }

  back() {
    this.router.navigate(['/kyc-management'])
  }

  approveKyc() {
   let url = "admin/approveKyc"
const data = {
  "userId" : this.kycData.userId,
  "kycId" : this.kycData._id
}
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res)=>{
      if(res.responseCode){
        
        this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
       $('#approveModal').modal('hide')
       this.router.navigate(['/kyc-management'])
      }
      else{
        this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
      }
    })
  
  }
  openModal() {
    $('#reject').modal('show')
  }
  rejectKyc() {
    
    let url = "admin/rejectKyc"
    const data = {
      "userId" : this.kycData.userId,
      "kycId" : this.kycData._id,
      "reason" : this.rejectKycReason
    }
        this.service.showSpinner()
        this.service.postApi(url,data,1).subscribe((res)=>{
          if(res.responseCode){
            
            this.service.hideSpinner()
           this.service.successToast(res.responseMessage)
           $('#reject').modal('hide')
           this.router.navigate(['/kyc-management'])
          }
          else{
            this.service.hideSpinner()
           this.service.successToast(res.responseMessage)
          }
        })
   
  }
}
