import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: ['./static-content.component.css']
})
export class StaticContentComponent implements OnInit {
  
  staticList: any=[];
  staticData  = [{pageName : 'About Us',id:1},
  {pageName : 'Privacy Policy',id:2},

  {pageName : 'Terms & Condition',id:3}
    ]
  constructor(public service:MainService,private route : Router) { }

  ngOnInit() {
   
  }
 
  editStatic(val){
this.route.navigate(['/edit-static-content'],{queryParams:{title : val}})
  }
    
  

  
}
