<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Admin Details</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <form [formGroup]="editAdminForm">
            <div class="outer-box">
                <div class="custom_tabs common-tabs">
                    <div class="tab-content card " style="padding: 2%; max-width: 625px; margin: auto;">
                        <!-- <div class="form-group row">
                        <div class="col-md-6" style="padding-left: 31%;">
                            <img src="assets/img/testimonial-img1.jpg" alt="">
                        </div>
                    </div> -->
                        <div class="form-group row">
                            <label class="col-md-5">Name</label>
                            <span class="col-md-1">:</span>
                            <span class="col-md-6">{{adminDetails?.firstName}} {{adminDetails?.lastName}}</span>
                            <!-- <span class="col-md-3"></span> -->
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5">Email</label>
                            <span class="col-md-1">:</span>
                            <span class="col-md-6">{{adminDetails?.email}}</span>
                            <!-- <span class="col-md-3"></span> -->
                        </div>
                        <div class="form-group row">
                            <!-- <label class="col-md-3">Phone Number :</label> -->
                            <label class="col-md-5">Mobile Number</label>
                            <span class="col-md-1">:</span>
                            <span class="col-md-6">{{adminDetails?.phoneNo}}</span>
                            <!-- <span class="col-md-3"></span> -->
                        </div>
                        <!-- <div class="form-group row">
                        <label class="col-md-3">SMS Auth :</label>
                        <span class="col-md-3"></span>
                        <button class="col-md-3" style="
                        max-width: 10%;">Enable</button>
                        <span class="col-md-3"></span>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3">Google Auth :</label>
                        <span class="col-md-3"></span>
                        <button class="col-md-3" style="
                        max-width: 10%;">Disable</button>
                        <span class="col-md-3"></span>
                    </div> -->
                        <div class="form-group row">

                            <label class="col-md-5">Role</label>
                            <span class="col-md-1">:</span>
                            <!-- <span class="col-md-6">{{adminDetails?.role}}</span> -->
                            <span class="col-md-6">Staff</span>
                            <!-- <span class="col-md-3"></span> -->
                        </div>

                        <table aria-describedby="">
                            <tr>
                                <th id="">Permission</th>
                                <th id="">Operations</th>
                            </tr>
                            <tr>
                                <td>Dashboard</td>

                                <td>
                                    <input type="checkbox" formControlName="dashboardChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Role Management</td>
                                <td>
                                    <input type="checkbox" formControlName="staffChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>User Management</td>
                                <td>
                                    <input type="checkbox" formControlName="userChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Hot Wallet Management</td>
                                <td>
                                    <input type="checkbox" formControlName="hotWalletChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>KYC Management</td>
                                <td>
                                    <input type="checkbox" formControlName="kycChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Wallet Management</td>
                                <td>
                                    <input type="checkbox" formControlName="walletChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Static Content Management</td>
                                <td>
                                    <input type="checkbox" formControlName="staticChecked" disabled>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>Logs Management</td>
                                <td>
                                    <input type="checkbox" formControlName="logsChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Ticket Management</td>
                                <td>
                                    <input type="checkbox" formControlName="ticketChecked" disabled>
                                </td>
                            </tr> -->

                            <tr>
                                <td>Trade Management</td>
                                <td>
                                    <input type="checkbox" formControlName="tradeChecked" disabled>
                                </td>
                            </tr>
                            <tr>
                                <td>Dispute Management</td>
                                <td>
                                    <input type="checkbox" formControlName="disputeChecked" disabled>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>Bank Management</td>
                                <td>
                                    <input type="checkbox" formControlName="bankChecked" disabled>
                                </td>
                            </tr> -->
                            <tr>
                                <td>Fee Management</td>
                                <td>
                                    <input type="checkbox" formControlName="feeChecked" disabled>
                                </td>
                            </tr>
                        </table>
                        &nbsp;
                        <div style="text-align: center">
                            <button class="btn  btn-theme" type="button" (click)="back()">Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- Table Responsive End -->
    </div>
</main>