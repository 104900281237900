import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
// import { filter } from 'minimatch';
import { MainService } from 'src/app/provider/main.service';
declare var $: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userListLength: any;
  kycpendingListlength: any;
  activeUserLength: any;
  blockedUserLength: any;
  pendingUserLength: any
  coinList: any = [];
  countByKycStatus: any;
  totalUserCount: any;
  advDisputeFeeCount: any;


///////////////////////
  totalKyc : any
  totalApproveKyc : any
  totalRejectKyc : any
  totalBlockUser : any
  totalUser : any
  totalActiveUser : any
  constructor(
    private service: MainService,
    private router: Router,
    // private toastr: ToastrManager,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) {

  }

  ngOnInit() {
    this.dasboardApi()
  }
  dasboardApi(){
    this.getTotalUser()
    this.getTotalActiveUser()
    this.getTotalKyc()
    this.getTotalBlockUser()
    this.getTotalApproveKyc()
    this.getTotalRejectKyc()
  }
  getTotalUser(){
    let url = "admin/totalUser"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalUser = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  getTotalActiveUser(){
    let url = "admin/totalActiveUser"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalActiveUser = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  getTotalBlockUser(){
    let url = "admin/totalBlockUser"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalBlockUser = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  getTotalKyc(){
    let url = "admin/totalKycRequest"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalKyc = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  
  getTotalApproveKyc(){
    let url = "admin/totalKycApprove"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalApproveKyc = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  getTotalRejectKyc(){
    let url = "admin/totalKycReject"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.totalBlockUser = res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)

      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)

      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  getUserList() {

  }

  // getListOFKYC Function
  getListOfKyc() {

  }


  // Get All The Coin Functionality
  getAllCoins() {

  }



  // sidemenu() {
  //   $(".btn-toggle,.close_panel").click(function () {
  //     $("body").toggleClass("toggle-wrapper");
  //   });
  // }

  // get advertisement, dispute and fee collection count
  getAdvertisementDisputeFeeCount() {
   
  }

}
