<main class="middle-content">
    <!-- Page Title Start -->
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Edit Staff</h1>
    </div>
    <!-- Page Title End -->
    <div class="content-section">
        <div class="outer-box" [formGroup]="editAdminForm">
            <div class="custom_tabs common-tabs">
                <div class="tab-content">
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">First Name </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input maxlength="100" class="form-control" formControlName="firstName"
                                        (keypress)="service.preventSpace($event)" type="text">
                                    <div class="text-danger"
                                        *ngIf="editAdminForm.get('firstName').invalid && (editAdminForm.get('firstName').dirty  || editAdminForm.get('firstName').touched)">
                                        <span *ngIf="editAdminForm.get('firstName').hasError('required')">*First name is
                                            required.</span>
                                        <span *ngIf="editAdminForm.get('firstName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Last Name </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input maxlength="100" class="form-control" formControlName="lastName"
                                        (keypress)="service.preventSpace($event)" type="text">
                                    <div class="text-danger"
                                        *ngIf="editAdminForm.get('lastName').invalid && (editAdminForm.get('lastName').dirty  || editAdminForm.get('lastName').touched)">
                                        <span *ngIf="editAdminForm.get('lastName').hasError('required')">*First name is
                                            required.</span>
                                        <span *ngIf="editAdminForm.get('lastName').hasError('pattern')">*Please enter
                                            valid name.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Email </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input maxlength="100" class="form-control" formControlName="email"
                                        (keypress)="service.preventSpace($event)" type="text">
                                    <div class="text-danger"
                                        *ngIf="editAdminForm.get('email').invalid && (editAdminForm.get('email').dirty  || editAdminForm.get('email').touched)">
                                        <span *ngIf="editAdminForm.get('email').hasError('required')">*Email is
                                            required.</span>
                                        <span *ngIf="editAdminForm.get('email').hasError('pattern')">*Please enter
                                            valid email.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                            <div style="display: flex">
                                <label class="col-md-4">Mobile Number </label>
                                <span class="col-md-2 text-center">:</span>
                                <div class="col-md-6">
                                    <input maxlength="100" class="form-control" formControlName="phoneNumber"
                                        (keypress)="service.preventSpace($event)" type="text">
                                    <div class="text-danger"
                                        *ngIf="editAdminForm.get('phoneNumber').invalid && (editAdminForm.get('phoneNumber').dirty  || editAdminForm.get('phoneNumber').touched)">
                                        <span *ngIf="editAdminForm.get('phoneNumber').hasError('required')">*Number is
                                            required.</span>
                                        <span *ngIf="editAdminForm.get('phoneNumber').hasError('pattern')">*Please enter
                                            valid number.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group row w-100">
                        <div class="col-md-6">
                            <label class="col-md-4">Gender </label>
                            <span class="col-md-2 text-center">:</span>
                            <div class="col-md-6">
                                <select class="form-control" formControlName="gender">
                                    <option value="">Select Gender</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="OTHER">Other</option>
                                </select>
                                <div class="text-danger"
                                    *ngIf="editAdminForm.get('gender').invalid && (editAdminForm.get('gender').dirty || editAdminForm.get('gender').touched)">
                                    <span *ngIf="editAdminForm.get('gender').hasError('required')">*Gender is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-md-4">Role </label>
                            <span class="col-md-2 text-center">:</span>
                            <div class="col-md-6">
                                <select class="form-control" formControlName="role">
                                    <option value="">Select Role</option>

                                    <option value="SUBADMIN">Staff</option>
                                </select>
                                <div class="text-danger"
                                    *ngIf="editAdminForm.get('role').invalid && (editAdminForm.get('role').dirty || editAdminForm.get('role').touched)">
                                    <span *ngIf="editAdminForm.get('gender').hasError('required')">*Role is
                                        required.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table aria-describedby="">
                        <tr>
                            <th id="">Permission</th>
                            <th id="">Operations</th>
                        </tr>
                        <tr>
                            <td>Dashboard</td>

                            <td>
                                <input type="checkbox" formControlName="dashboardChecked"
                                    (change)="checkboxClick('DASHBOARD',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Role Management</td>
                            <td>
                                <input type="checkbox" formControlName="staffChecked"
                                    (change)="checkboxClick('STAFF_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>User Management</td>
                            <td>
                                <input type="checkbox" formControlName="userChecked"
                                    (change)="checkboxClick('USER_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>

                        <tr>
                            <td>Hot Wallet Management</td>
                            <td>
                                <input type="checkbox" formControlName="hotWalletChecked"
                                    (change)="checkboxClick('HOT_COLD_LIMIT_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>KYC Management</td>
                            <td>
                                <input type="checkbox" formControlName="kycChecked"
                                    (change)="checkboxClick('KYC_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Wallet Management</td>
                            <td>
                                <input type="checkbox" formControlName="walletChecked"
                                    (change)="checkboxClick('WALLET_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Static Content Management</td>
                            <td>
                                <input type="checkbox" formControlName="staticChecked"
                                    (change)="checkboxClick('STATIC_CONTENT',$event.target.checked)">
                            </td>
                        </tr>


                        <tr>
                            <td>Trade Management</td>
                            <td>
                                <input type="checkbox" formControlName="tradeChecked"
                                    (change)="checkboxClick('TRADE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                        <tr>
                            <td>Dispute Management</td>
                            <td>
                                <input type="checkbox" formControlName="disputeChecked"
                                    (change)="checkboxClick('DISPUTE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>

                        <tr>
                            <td>Fee Management</td>
                            <td>
                                <input type="checkbox" formControlName="feeChecked"
                                    (change)="checkboxClick('FEE_MANAGEMENT',$event.target.checked)">
                            </td>
                        </tr>
                    </table>
                    &nbsp;
                    <div style="text-align: center">
                        <button class="btn  btn-theme" type="button" [disabled]="editAdminForm.invalid"
                            (click)="addStaff()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Table Responsive End -->
    </div>
</main>