import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  // token: string;

  constructor(public service: MainService, public route: Router) { }

  ngOnInit() {
    // let url = window.location.href.split('/')
    // this.token = window.location.href.split('=')[1];
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }


  // Reset Password Functionality
  resetPasswordFunc() {
    let url = "admin/changePassword"
    const data = {
      oldPassword : this.resetPasswordForm.value.oldPassword,
      newPassword : this.resetPasswordForm.value.password,
      confirmPassword : this.resetPasswordForm.value.confirmPassword
    }
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
        this.service.errorToast(err)
    })
  }

}
