import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.css']
})
export class EditStaticContentComponent implements OnInit {

  title : any = ""
  about: any;
  staticTitle : any
  description: any;
  constructor(public service: MainService, public route: ActivatedRoute) {
    this.route.queryParams.subscribe((res) => {

   this.title = res.title
     console.log(res.title);
     
    })
   
   }

  ngOnInit() {
    this.staticTitle = this.title
    this.getStaticContent()
    
  }
  getStaticContent(){
    let url = "static/viewStaticContent"
    let type = ''
    if(this.title == 'About Us'){
      type = 'About_Us'
    }
    else if(this.title == 'Privacy Policy'){
      type = 'Privacy_Policy'
    }
    else{
      type = 'T&C'
    }
    const data = {
      type : type
    }
    this.service.showSpinner()
    this.service.postApi(url,data,0).subscribe((res)=>{
      if(res.responseCode == 200){
        this.description = res.result.description
        console.log(this.description)
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
        this.service.errorToast(err)
    })
  }
  updateStaticContent(){
    let url = "static/editStaticContent"
    
    
    const data = {
      staticId : '60d2bfe48066145d6cbe69ed',
      'title' : this.staticTitle,
      description : this.description
    }
    this.service.showSpinner()
    this.service.postApi(url,data,1).subscribe((res)=>{
      if(res.responseCode == 200){
      
       this.getStaticContent()
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
        this.service.errorToast(err)
    })
  }
 

}
