<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Trade Management</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new">
                            <div class="row">
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Status</span>
                                            <select class="form-control" [(ngModel)]="tradeStatus">
                                                <option value="" placeholder="Select">Select</option>
                                                <option value="PENDING">Pending</option>
                                                <option value="PAID">Paid</option>
                                                <option value="CANCEL">Cancel</option>
                                                <option value="DISPUTE">Dispute</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Country</span>
                                            <select class="form-control" [(ngModel)]="country">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of countryList" [value]="item?.country">
                                                    {{item?.country}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Start Date</span>
                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}" name="formdate"
                                                [(ngModel)]="calender.formdate" (change)="formdate()"
                                                class="form-control datepicker" placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">End Date</span>
                                            <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                                (change)="todate()" [disabled]="!calender.formdate"
                                                [(ngModel)]="calender.todate" class="form-control datepicker"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Payment Method</span>
                                            <select class="form-control" [(ngModel)]="paymentType">
                                                <option value="">Select</option>
                                                <option value="NationalBankTransfer">National Bank Transfer</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="paypal">PAYPAL</option>
                                                <option value="paytm">PAYTM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Type</span>
                                            <select class="form-control" [(ngModel)]="type">
                                                <option value="">Select</option>
                                                <option value="SELL">Sell</option>
                                                <option value="BUY">Buy</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 user-pl">
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()"
                                            class="btn  btn-theme mr-2">Search</button>
                                        <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                                        <button type="submit" (click)="exportAsXLSX()"
                                        class="btn  btn-theme mr-2" style="margin-left: 2%;">EXPORT AS EXCEL</button>
                                    </div>
                                </div>
                            </div>


                            <!-- next row -->
                            <!-- <div class="row mt-3">
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Payment Method</span>
                                            <select class="form-control" [(ngModel)]="paymentType">
                                                <option value="">Select</option>
                                                <option value="NationalBankTransfer">National Bank Transfer</option>
                                                <option value="IMPS">IMPS</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Type</span>
                                            <select class="form-control" [(ngModel)]="type">
                                                <option value="">Select</option>
                                                <option value="SELL">Sell</option>
                                                <option value="BUY">Buy</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <!-- <div class="transaction"><h3>Trade List</h3></div> -->
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">Trade ID</th>
                                        <th id="">Buyer</th>
                                        <th id="">Seller</th>

                                        <th id="">Trade Date & Time</th>
                                        <th id="">Status</th>
                                        <th id="">Trade Type</th>
                                        <th id="">Payment Method</th>
                                        <th id="">Currency Type</th>
                                        <!-- <th id="">Trade Amount</th> -->
                                        <th id="">Transaction Fee</th>
                                        <th id="">Exchange</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr
                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: tradeListlength}; let i=index; "> -->
                                    <tr
                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                        <td (click)="navigate(data.tradeId)"><u
                                                class="trade-table-data">{{data?.tradingId || '---'}}</u></td>
                                        <td>{{data?.buyer || '---'}}</td>
                                        <td>{{data?.seller || '---'}}</td>

                                        <td>{{(data?.creationTime | date:'medium') || '---'}}</td>
                                        <td>{{data?.tradeStatus || '---'}}</td>
                                        <td>{{data?.type || '---'}}</td>
                                        <td>{{data?.paymentType || '---'}}</td>
                                        <td>{{data?.tradeAmount || '---'}} {{data?.fiatCoin}}</td>
                                        <!-- <td>{{data?.tradeAmount || '---'}}</td> -->
                                        <td>{{data?.tradeFee || '---'}}</td>
                                        <td>{{data?.totalBTC || '---'}}</td>
                                    </tr>
                                    <tr *ngIf="tradeList?.length==0">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5>No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="custom-pagination mt20 text-center" style="float: right;"
                            *ngIf="tradeListlength > 5 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="totalItems > 5 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</main>
