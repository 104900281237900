import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {

  form : FormGroup ;
  faqList: any;
  faqId: any;
  updateButton : boolean = false
  constructor(public mainService : MainService,private activatedroute : ActivatedRoute , private router : Router ) {
    this.activatedroute.queryParams.subscribe((res)=>{
      this.faqId = res.id;
    })
   }

  ngOnInit() {

    this.form = new FormGroup({
      "question" : new FormControl('' , Validators.required) ,
      "answer" : new FormControl('', Validators.required) ,
    });
    this.getFaq()


  }

 getFaq(){
  let url = "faq/faqs/"+this.faqId
  this.mainService.showSpinner()
  this.mainService.getApi(url,1).subscribe((res)=>{
    if(res.responseCode == 200){
      this.form.patchValue({
        question : res.data.question,
        answer : res.data.answer
      })
      this.mainService.hideSpinner()
      this.mainService.successToast(res.responseMessage)
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.responseMessage)
    }
  })
 }

 updateFaq(){
  let url = "faq/editFaqs"
  
  console.log(this.form.touched,this.form.dirty);

  const data = {
    "faqId" : this.faqId,
    "answer": this.form.value.answer,
    "question": this.form.value.question
  }
  this.mainService.showSpinner()
  this.mainService.putApi(url,data,1).subscribe((res)=>{
    if(res.responseCode == 200){

      this.mainService.hideSpinner()
      this.mainService.successToast(res.responseMessage)
      this.router.navigate(['faq'])
    }
    else{
      this.mainService.hideSpinner()
      this.mainService.errorToast(res.responseMessage)
    }
  })
 }
 inputFunct(){
   console.log(this.updateButton);

   if(this.form.valid && (this.form.touched || this.form.dirty)){
    this.updateButton = true
   }
   else{
     this.updateButton = false
   }
 }

}
