import { Component } from '@angular/core';
import { MainService } from './provider/main.service';
declare var $:any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ivendr-app-admin-panel';
  constructor(public service: MainService){}
  ngOnInit() {
  //   $(".btn-toggle,.close_panel").click(function() {
  //     $("body").toggleClass("toggle-wrapper");
  // });
  // this.service.initSocketSupportChat()
  }
}
