<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form class="login_box_outer" [formGroup]="forgotPassword">
                    <div class="login-box max-WT-520">
                        <div class="login-right-block">
                            <div class="text-center">
                                <img src="/assets/img/ivnlogo.png" class="login-logo2" alt="">
                            </div>
                            <div class="login-heading">
                                <h4 style="color:black">FORGOT PASSWORD?</h4>
                            </div>
                            <div class="login-box-body">
                                <p class="common-paragrph text-center">Enter the account details to reset the password.
                                </p>
                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control"
                                        placeholder="Email address" required />
                                    <span class="error">
                                        <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty"
                                            class="error" padding>*Please enter valid email.</p>
                                    </span>
                                </div>
                                <div class="form-group text-center">
                                    <button type="submit" class="btn btn-login btn-small  width100 font-100 mr-2"
                                        (click)="onForgot()" [disabled]="!forgotPassword.valid">SUBMIT</button>
                                    <button type="submit" class="btn btn-login btn-small  width100 font-100"
                                        [routerLink]="['/login']">BACK</button>
                                </div>
                            </div>
                            <a (click)="onResend()" class="text-center mt20 reset-link">Resend</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>


<!-- otp modal -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="color: white;">Please Enter OTP</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color: white;">&times;</span>
                </button>
            </div>
            <form>
                <div class="modal-body mb40 mt40" style="display: flex;
                justify-content: center;
                white-space: nowrap;">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" (keypress)="service.NumOnly($event)" [config]="{length:5}"></ng-otp-input>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="veryfyOtp()">Verify</button>
                </div>
            </form>
        </div>
    </div>
</div>