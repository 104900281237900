import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;

  constructor(public service: MainService, public route: Router) { }

  ngOnInit() {
    this.token = window.location.href.split('=')[1];
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
  }


  // Reset Password Functionality
  resetPasswordFunc() {
    
   let url = "admin/resetPassword"
   const data = {
    email: localStorage.getItem('userEmail'),
    newPassword : this.resetPasswordForm.value.password,
    confirmPassword : this.resetPasswordForm.value.confirmPassword
   }
   this.service.showSpinner()
   this.service.postApi(url,data,0).subscribe((res)=>{
     if(res.responseCode){
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
       this.route.navigate(['/login'])
     }
     else{
      this.service.hideSpinner()
      this.service.errorToast(res.responseMessage)
     }
   },(err)=>{
    this.service.hideSpinner()
    this.service.errorToast(err)
   })
  }

}
