
<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View KYC </h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="card mt-5">
                                <div class="card-header">
                                    KYC DETAILS
                                </div>
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="col-md-6">
                                            <div class="row mt-2">
                                                <label class="col-md-4">KYC ID </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">{{kycData?.kycId || '---'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4">KYC Status </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">{{kycData?.kycStatus || '---'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4">Created On </label>
                                                <span class="col-md-1">:</span>
                                                <label
                                                    class="col-md-6">{{kycData?.createdAt | date:'medium' || '---'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4">Type </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">KYC</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mt-2">
                                                <label class="col-md-4">ID Document Type </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">{{kycData?.docName || '---'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4">ID Number </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">{{kycData?.docIdNumber || '---'}}</label>
                                            </div>
                                            <div class="row mt-2">
                                                <label class="col-md-4">Document Status </label>
                                                <span class="col-md-1">:</span>
                                                <label class="col-md-6">{{kycData?.documentStatus || '---'}}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 3%">
                                <div class="col" style="text-align: center;position: relative; left: 7%;">
                                    <img [src]="kycData?.frontPage || 'assets/img/testimonial-img1.jpg'"
                                        class="zoom" style="max-width: 60%;" alt=""><br>
                                    <label>(Front)</label>
                                </div>
                                <div class="col" style="text-align: center;">
                                    <img [src]="kycData?.backPage || 'assets/img/testimonial-img1.jpg'" class="zoom"
                                        alt="" style="max-width: 60%;"><br>
                                    <label>(Back)</label>
                                </div>
                            </div>

                            
                        </div>
                        <div style="text-align: center; margin-top: 3%;margin-bottom:3% ;">
                            <div><button class="btn btn-theme ml-1" style="width: 15%" 
                                *ngIf="kycData?.kycStatus == 'PENDING'"    (click)="openApproveModal()">Approve</button>
                                <button class="btn btn-theme ml-1" style="width: 15%"
                                *ngIf="kycData?.kycStatus == 'PENDING'"   (click)="openModal()">Reject</button>
                                <button class="btn btn-theme ml-1" style="width: 15%" (click)="back()">Back</button>
                            </div>
                        </div>
                    </div>

                </div>
                

            </div>
        </div>

    </main>
</div>
<!-- Wrapper End -->
<!-- approve_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to approve this document?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="approveKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- approve_modal End -->
<!-- reject_modal Start -->
<div class="modal fade global-modal reset-modal" id="reject">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>

                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to reject this document?</p>
                                <textarea type="text" [(ngModel)]="rejectKycReason" placeholder="Specify reason for rejection.."
                                    class="form-control" [ngModelOptions]="{standalone: true}"
                                    maxlength="50"></textarea>
                                <div style="margin-top: 11px">
                                    <button type="submit" class="btn btn-info mr-2" (click)="rejectKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- reject_modal End -->