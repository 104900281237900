<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Privacy Policy</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row ">
                            <label class="col-md-12 mb-2">Page Name :</label>
                            <div class="col-md-12">
                                <textarea class="form-control common-textarea" [(ngModel)]="dataa.pageKey"
                                    readonly></textarea>
                            </div>
                        </div>
                        <div class="form-group row ">
                            <label class="col-md-12 mb-2F">Description:</label>
                            <div class="col-md-12">

                                <ck-editor name="editor1" [(ngModel)]="dataa.pageData" skin="moono-lisa" language="en"
                                    [fullPage]="true"></ck-editor>


                            </div>
                        </div>
                        <div class="text-center mt40">
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green"
                                (click)="savePrivacy()">Update</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5"
                                [routerLink]="['/static-content']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>