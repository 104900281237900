<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Dashboard</h1>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center ">
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-users fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Registered Users</h2>
                        <h4>{{totalUser || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-user-ninja fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Active Users</h2>
                        <h4>{{totalActiveUser || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/user-management']">
                        <div class="dash_icn">
                            <i class="fas fa-user-slash fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Blocked Users</h2>
                        <h4>{{totalBlockUser || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/kyc-management']">
                        <div class="dash_icn">
                            <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Kyc Request</h2>
                        <h4>{{totalKyc || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/kyc-management']">
                        <div class="dash_icn">
                            <i class="fas fa-file fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Pending KYC</h2>
                        <h4>{{countByKycStatus || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-users-cog fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Deposit Request
                        </h2>
                        <h4>{{coinList.depositCount || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100">
                        <div class="dash_icn">
                            <i class="fas fa-coins fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Coins</h2>
                        <h4>{{coinList.coinCount || 0}}</h4>
                    </div>
                </li>

                <!--  -->
               <!--  <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/trade-management']">
                        <div class="dash_icn">
                            <i class="fas fa-bullhorn fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Advertisement
                        </h2>
                        <h4>{{advDisputeFeeCount?.totalAdvertisementCount || 0}}</h4>
                    </div>
                </li> -->
             <!--    <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/trade-management']">
                        <div class="dash_icn">
                            <i class="fas fa-bullhorn fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Open Advertisement</h2>
                        <h4>{{advDisputeFeeCount?.totalOpenAdvertisementCount || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/trade-management']">
                        <div class="dash_icn">
                            <i class="fas fa-bullhorn fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Closed Advertisement</h2>
                        <h4>{{advDisputeFeeCount?.totalCloseAdvertisementCount || 0}}</h4>
                    </div>
                </li> -->
               <!--  <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/trade-management']">
                        <div class="dash_icn">
                            <i class="fas fa-bullhorn fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Cancelled Advertisement</h2>
                        <h4>{{advDisputeFeeCount?.totalCanceledAdvertisementCount || 0}}</h4>
                    </div>
                </li> -->
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/dispute-management']">
                        <div class="dash_icn">
                            <i class="fas fa-gavel fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Dispute
                        </h2>
                        <h4>{{advDisputeFeeCount?.totalDisputeCount || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/dispute-management']">
                        <div class="dash_icn">
                            <i class="fas fa-gavel fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Open Dispute</h2>
                        <h4>{{advDisputeFeeCount?.totalOpenDisputeCount || 0}}</h4>
                    </div>
                </li>

                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/dispute-management']">
                        <div class="dash_icn">
                            <i class="fas fa-gavel fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Closed Dispute</h2>
                        <h4>{{advDisputeFeeCount?.totalClosedDisputeCount || 0}}</h4>
                    </div>
                </li>
                <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/dispute-management']">
                        <div class="dash_icn">
                            <i class="fas fa-gavel fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Inprogress Dispute
                        </h2>
                        <h4>{{advDisputeFeeCount?.totalInprogressDisputeCount || 0}}</h4>
                    </div>
                </li>
<!--                 <li class="d-inline-flex align-items-center justify-content-center bg-blue">
                    <div class="w-100" [routerLink]="['/advertisement-management']">
                        <div class="dash_icn">
                            <i class="fas fa-file-invoice-dollar fa-3x" aria-hidden="true"></i>
                        </div>
                        <h2>Total Advertisement Fee Collection</h2>
                        <h4>{{advDisputeFeeCount?.totalAdvertisementFeeCollectionCount || 0}}</h4>
                    </div>
                </li> -->
                <!--  -->
            </ul>
        </div>
    </main>
</div>


<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div class="inner_border_area">
                <div class="modal-body  text-center">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="box-title mb40 d-inline-block">
                                <h2>Are you sure?</h2>
                                <p>You won’t be able to revert this!</p>
                            </div>
                            <div class="max-WT-300 d-inline-block">
                                <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                                <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                    data-dismiss="modal">CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->

<!-- Signout Modal -->
<!-- Change Password_modal Start -->
<div class="modal fade global-modal reset-modal" id="change_passwprd_modal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="inner_border_area">
                    <div class="modal-header ">
                        <h4 class="modal-title text-center">Change Password</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center modal_flax_height">
                            <div class="col">
                                <div class="form-group">
                                    <label class="control-labe">Old Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">New Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-labe">Confirm Password</label>
                                    <input class="form-control" placeholder="" required="" type="text">
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <button type="submit"
                                            class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                    </div>
                                    <div class="col-6">
                                        <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                            data-dismiss="modal">CANCEL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>