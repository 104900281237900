<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Finance Management <span style="text-transform: uppercase;"></span></h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <!-- tab section -->
                        <div class="custom_tabs common-tabs">
                            <div class="row mb20 justify-content-center">
                                <div class="col-sm-12">
                                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                [ngClass]="{'active': currTab=='BUY'}"
                                                (click)="selectTab('BUY')" href="javascript:;">Buy</a>
                                        </li>
                                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                [ngClass]="{'active': currTab=='SELL'}"
                                                (click)="selectTab('SELL')" href="javascript:;">Sell</a>
                                        </li>
                                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                                [ngClass]="{'active': currTab=='FEE'}" (click)="selectTab('FEE')"
                                                href="javascript:;">Fee Collection</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- common search section -->
                        <div class="sec_head_new">
                            <div class="row">
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Start Date</span>
                                            <input type="date" max="{{minAge | date:'yyyy-MM-dd'}}" name="fromdate"
                                                [(ngModel)]="calender.fromdate" (change)="fromdate()"
                                                class="form-control" placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">End Date</span>
                                            <input type="date" name="todate" min="{{fromDate | date:'yyyy-MM-dd'}}"
                                                (change)="todate()" [disabled]="!calender.fromdate"
                                                [(ngModel)]="calender.todate" class="form-control"
                                                placeholder="Search by date">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 user-pl">
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()"
                                            class="btn btn-theme mr-2">Search</button>
                                        <button type="submit" (click)="reset()" class="btn btn-theme">Reset</button>
                                    </div>
                                </div>
                                <div class="col-md-2 user-pl">
                                    <div class="user-frmbtn" >

                                        <button type="submit"  class="btn btn-theme"
                                            (click)="exportAsXLXS()">Export As
                                            Excel</button>
                                    </div>
                                </div>
                                <div *ngIf="currTab == 'FEE'" class="col-md-2 user-pl">
                                    <div class="user-frmbtn" >

                                        <label style="margin-top: 8px;"> Collected: {{feeColletion || 0}} BTC</label>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <!-- end common search section -->


                        <div *ngIf="currTab == 'BUY'" class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                      <th id="">ID</th>
                                      <th id="">Buyer</th>
                                      <th id="">Seller</th>
                                      <th id="">Coin</th>
                                      <th id="">Amount</th>
                                      <th id="" >Trade Id</th>
                                      <th id="">Payment Method </th>
                                      <th id="">Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of responseDataArray | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index;">

                                        <td>{{data?.userId}}</td>
                                        <td>{{data?.buyer}}</td>
                                        <td>{{data?.seller}}</td>
                                        <td>{{data?.fiatCoin}}</td>

                                        <!-- <td>{{data?.txnHash || '---'}}</td> -->


                                        <td>{{data?.tradeAmount}}</td>
                                        <td>
                                          {{data?.tradeId || '---'}}

                                      </td>
                                        <td>{{data?.paymentType}}</td>
                                        <td>{{data?.creationTime | date:'medium'}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="totalItems == ''">
                                        <td colspan="8"> No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt20 text-right" >
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>
                        </div>



                        <div *ngIf="currTab == 'SELL'" class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                      <th id="">ID</th>
                                      <th id="">Buyer</th>
                                      <th id="">Seller</th>
                                      <th id="">Coin</th>
                                      <th id="">Amount</th>
                                      <th id="" >Trade Id</th>
                                      <th id="">Payment Method </th>
                                      <th id="">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of responseDataArray | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index;">
                                        <td>{{data?.userId}}</td>
                                        <td>{{data?.buyer}}</td>
                                        <td>{{data?.seller}}</td>
                                        <td>{{data?.fiatCoin}}</td>

                                        <!-- <td>{{data?.txnHash || '---'}}</td> -->


                                        <td>{{data?.tradeAmount}}</td>
                                        <td>
                                          {{data?.tradeId|| 'N/A'}}

                                      </td>
                                        <td>{{data?.paymentType}}</td>
                                        <td>{{data?.creationTime | date:'medium'}}
                                        </td>
                                      </tr>

                                    <tr *ngIf="totalItems == ''">
                                        <td colspan="8"> No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <!-- table for admin -->
                        <div *ngIf="currTab == 'FEE'" class="table-responsive">
                            <table class="table table-bordered" aria-describedby="trade table">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">ID</th>
                                        <th id="">Date</th>
                                        <th id="">Buyer</th>
                                        <th id="">Seller</th>
                                        <th id="">Crypto Amount</th>
                                        <th id="">Fiat Amount</th>
                                        <th id="">Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of responseDataArray | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                        <td>{{data?.tradingId}}</td>
                                        <td>{{data?.creationTime | date:'medium'}}</td>
                                        <td>{{data?.buyer}}</td>
                                        <td>{{data?.seller}}</td>
                                        <td>{{data?.totalBTC}}</td>
                                        <td>{{data?.tradeAmount}}</td>
                                        <td>{{data?.tradeFee}}</td>
                                    </tr>
                                    <tr *ngIf="totalItems==''">
                                        <td colspan="11" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <h5>No record found</h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt20 text-right" >
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>

                        </div>
                        <!-- end table for admin -->

                        <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="totalItems > 5 ">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
