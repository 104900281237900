import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';
// import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-admin',
  templateUrl: './view-admin.component.html',
  styleUrls: ['./view-admin.component.css']
})
export class ViewAdminComponent implements OnInit {
  userId: any;
  adminDetails: any;
  editAdminForm: FormGroup;
  adminPrivileges: any;

  constructor(public router: Router, public param: ActivatedRoute, public service: MainService) {
    this.param.params.subscribe((res) => {
      this.userId = res.id;
      console.log("userId", res)
    })
  }

  ngOnInit() {
    this.editAdminForm = new FormGroup({
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      userChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile();
  }

  back() {
    this.router.navigate(['/admin-management'])
  }

  // get admin details
  getProfile() {
    let dataa = {
      "primaryIdCommonPerRequest": this.userId
    }
 
  }

}