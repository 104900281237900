import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FooterComponent } from './pages/footer/footer.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { KycManagementComponent } from './pages/kyc-management/kyc-management.component';
import { TradeManagementComponent } from './pages/trade-management/trade-management.component';
import { TradeDetailsComponent } from './pages/trade-details/trade-details.component';
import { DisputeManagementComponent } from './pages/dispute-management/dispute-management.component';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { UserDetailsComponent } from './pages/user-details/user-details.component';
import { AddAdminComponent } from './pages/add-admin/add-admin.component';
import { ViewAdminComponent } from './pages/view-admin/view-admin.component';
import { KycActionPageComponent } from './pages/kyc-action-page/kyc-action-page.component';
import { FinancesComponent } from './pages/finances/finances.component';
import { FeeManagementComponent } from './pages/fee-management/fee-management.component';
import { StaticContentComponent } from './pages/static-content/static-content.component';

import { PrivacyComponent } from './pages/privacy/privacy.component';

import { EditAdminComponent } from './pages/edit-admin/edit-admin.component';
import { AuthGuard } from './guard/auth.guard';
import { SettingComponent } from './pages/setting/setting.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { DisputeTradeDetailsComponent } from './pages/dispute-trade-details/dispute-trade-details.component';
import { HotcoldwalletManagementComponent } from './pages/hotcoldwallet-management/hotcoldwallet-management.component';
import { ReferralManagementComponent } from './pages/referral-management/referral-management.component';
import { ReferralDetailsComponent } from './pages/referral-details/referral-details.component';
import { ReferralSettingComponent } from './pages/referral-setting/referral-setting.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { EditStaticContentComponent } from './pages/static-content/edit-static-content/edit-static-content.component';
import { FaqComponent } from './pages/static-content/faq/faq.component';
import { AddFaqComponent } from './pages/static-content/add-faq/add-faq.component';
import { ViewFaqComponent } from './pages/static-content/view-faq/view-faq.component';
import { EditFaqComponent } from './pages/static-content/edit-faq/edit-faq.component';
import { ViewManagementComponent } from './pages/view-management/view-management/view-management.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'footer', component: FooterComponent },

  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
  
  
  
  { path: 'user-details', component: UserDetailsComponent, canActivate: [AuthGuard] },
  { path: 'view-user', component: ViewManagementComponent, canActivate: [AuthGuard] },
  


  { path: 'admin-management', component: AdminManagementComponent, canActivate: [AuthGuard] },
  { path: 'add-admin', component: AddAdminComponent, canActivate: [AuthGuard] },
  { path: 'view-admin/:id', component: ViewAdminComponent, canActivate: [AuthGuard] },
  { path: 'edit-admin', component: EditAdminComponent, canActivate: [AuthGuard] },


  { path: 'kyc-management', component: KycManagementComponent, canActivate: [AuthGuard] },
  { path: 'kyc-action/:id', component: KycActionPageComponent, canActivate: [AuthGuard] },

  { path: 'trade-management', component: TradeManagementComponent, canActivate: [AuthGuard] },
  { path: 'trade-details/:id', component: TradeDetailsComponent, canActivate: [AuthGuard] },

  { path: 'dispute-management', component: DisputeManagementComponent, canActivate: [AuthGuard] },
  { path: 'dispute-trade-details/:id', component: DisputeTradeDetailsComponent },

  { path: 'finances', component: FinancesComponent, canActivate: [AuthGuard] },
  { path: 'fee-management', component: FeeManagementComponent, canActivate: [AuthGuard] },

  { path: 'static-content', component: StaticContentComponent, canActivate: [AuthGuard] },
  { path: 'edit-static-content', component: EditStaticContentComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'add-faq', component: AddFaqComponent, canActivate: [AuthGuard] },
  { path: 'view-faq', component: ViewFaqComponent, canActivate: [AuthGuard] },
  { path: 'edit-faq', component: EditFaqComponent, canActivate: [AuthGuard] },
  


  { path: 'privacy/:id', component: PrivacyComponent, canActivate: [AuthGuard] },
 

  { path: 'setting', component: SettingComponent, canActivate: [AuthGuard] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'hotcoldwallet-management', component: HotcoldwalletManagementComponent },
  { path: 'referral-management', component: ReferralManagementComponent },
  { path: 'referral-details/:id', component: ReferralDetailsComponent },
  { path: 'referral-setting', component: ReferralSettingComponent },
  // notification management
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
