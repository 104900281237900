
<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Referral Settings</h1>

        </div>
        <!-- Page Title End -->
        <div class="content-section">
            
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">

                        <form [formGroup]="referralForm">
                            <div class="form-group row">
                                <label class="col-md-5">Referral </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-6 ">
                                    <label class="switch">
                                        <input type="checkbox" [(ngModel)]="refToggle" [ngModelOptions]="{standalone: true}" (click)="referralEnableDisable()">
                                        <span class="slider round"></span>
                                      </label>
                                   
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5">Referral Percentage </label>
                                <div class="col-md-1"> :</div>
                                <div class="col-md-6">
                                    <input type="text" class="form-control" formControlName="amount">
                                </div>
                            </div>
                        </form>
                        <div class="text-left mt40">
                            <a routerLinkActive="router-link-active"
                                class="btn btn-large  max-WT-200 font-100 btn-green mr-2" (click)="getReferralSetting()">Update Setting</a>
                            <a href="javascript:void(0)" class="btn btn-large  max-WT-200 font-100 btn-green ml5 "
                                [routerLink]="['/setting']">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- Middle Content End -->
</div>
<!-- Wrapper End -->
<!--Modal Start-->
<div class="modal fade global-modal reset-modal" id="delet_farms_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <!-- Modal body -->
            <div class="modal-body  text-center">
                <div class="row align-items-center modal_flax_height">
                    <div class="col">
                        <div class="box-title mb40 d-inline-block">
                            <h2>Are you sure?</h2>
                            <p>You won’t be able to revert this!</p>
                        </div>
                        <div class="max-WT-300 d-inline-block">
                            <button type="button" class="btn btn-gray btn-large radius0 btn-block">YES</button>
                            <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                data-dismiss="modal">CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- signout_modal Start -->

<!-- Signout Modal -->
<!-- Change Password_modal Start -->
<div class="modal fade global-modal reset-modal" id="change_passwprd_modal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header ">
                    <h4 class="modal-title text-center">Change Password</h4>

                </div>
                <div class="modal-body">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="form-group">
                                <label class="control-labe">Old Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="form-group">
                                <label class="control-labe">New Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="form-group">
                                <label class="control-labe">Confirm Password</label>
                                <input class="form-control" placeholder="" required="" type="text">
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="submit"
                                        class="btn btn-gray btn-large radius0 btn-block">SUBMIT</button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-red btn-large radius0 btn-block"
                                        data-dismiss="modal">CANCEL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>