import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { ServiceService } from '../service.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';


declare var $
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup;
 
  otp : any
  constructor(
    private router: Router,
    public service: MainService
  ) { }

  ngOnInit() {
    this.forgotPassword = new FormGroup({

      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)),
    })
  }


  onForgot() {
    let url = "admin/forgotPassword"
    const data = {
      email : this.forgotPassword.value.email
    }
   this.service.showSpinner()
   this.service.postApi(url,data,0).subscribe((res)=>{
     if(res.responseCode == 200){
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
      $('#exampleModal').modal('show');



     }
     else{
      this.service.hideSpinner()
      this.service.errorToast(res.responseMessage)
     }
   },(err)=>{
    this.service.hideSpinner()
    this.service.errorToast(err)
  })
    
    
   
  }
  onOtpChange(e) {
    this.otp = e;
  }

  veryfyOtp(){
    let url = "admin/otpVerify"
    const data = {
      email : this.forgotPassword.value.email,
      otp : this.otp
    }
    this.service.showSpinner()
    this.service.postApi(url,data,0).subscribe((res)=>{
      if(res.responseCode == 200){
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
        $('#exampleModal').modal('hide');
        localStorage.setItem('userEmail',this.forgotPassword.value.email)
        this.router.navigate(['/reset-password'])
 
 
 
      }
      else{
       this.service.hideSpinner()
       this.service.errorToast(res.responseMessage)
      }
    },(err)=>{
      this.service.hideSpinner()
      this.service.errorToast(err)
    })
  }
  onResend() {
   
  }

}
