import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// import { ServiceService } from '../service.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
declare var $: any;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  userId : any
  userView : any = []
  currTab : any
  pageNumber : any = 1
  total : any ;
  constructor(public router: Router, public service: MainService, public param: ActivatedRoute) {
    this.param.queryParams.subscribe((res: any) => {
      console.log("jdhsgfjsdhg", res)
      this.userId = res.id
    })
  }

  ngOnInit() {
   this.getUserDetail()
   
  }

  getUserDetail(){
    let url = "admin/viewUser/"+this.userId
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode){
          this.userView =  res.result
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
        this.service.hideSpinner()
        this.service.errorToast(res.responseMessage)
      }
    },(res)=>{
      this.service.hideSpinner()
      this.service.errorToast(res.responseMessage)
    })
  }
  selectTab(tab){

  }
 
  pagination(event){
    this.pageNumber = event
  }

  

}
