import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginData: any;
  Obj: any;
  ipAddress: string;
 
  
userId:any
  constructor(private router: Router, private fb: FormBuilder, public service: MainService) { }

  ngOnInit() {
    if(localStorage.getItem('userEmail')){
      localStorage.removeItem('userEmail')
    }
    this.checkInput();

  }

  

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      email: new FormControl(remData.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      password: [pass, [Validators.required]],
      rememberMe: new FormControl(remData.rememberMe)
    })
  }




  onLogin() {
    let url='admin/login'
        let data = {
          'email': this.loginForm.value.email,
          'password': this.loginForm.value.password
        }
      
        this.service.loginStatus.next(true)
        
        this.service.showSpinner()
        this.service.postApi(url, data, 0).subscribe((res: any) => {
          console.log("login response ==>", res)
          this.service.hideSpinner()
          if (res.responseCode == 200) {
            // this.service.vendorPermissions.next(res.result.permissions)
            this.service.hideSpinner();
            this.service.successToast(res.responseMessage)
            // this.userId=res.result.result._id
            // console.log(this.userId)
            localStorage.setItem('token', res.result.token);
            localStorage.setItem('userType', res.result.userType)
            this.service.loginStatus.next(true)
            this.router.navigate(['dashboard'])
            // this.getProfile()
            this.service.loginData.next('res.result')
    
            console.log(this.loginForm.value.rememberMe)
            if (this.loginForm.value.rememberMe) {
              let remData = {
                "mobileNumber": window.btoa(this.loginForm.value.number),
                "password": window.btoa(this.loginForm.value.password),
                "rememberMe": window.btoa(this.loginForm.value.rememberMe)
              }
              localStorage.setItem('rememberMe', JSON.stringify(remData))
            }
            else {
              localStorage.removeItem("rememberMe");
            }
          } else {
            this.service.hideSpinner();
            this.service.errorToast('inValid credential')
          }
        }, error => {
          this.service.hideSpinner()
          this.service.errorToast('Something went wrong');
        }
        )
      }
    }

