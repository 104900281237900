<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title">Disputed Trade Details</h1>
  </div>
  <div class="content-section" style="margin-bottom: 30px;">
    <div class="outer-box">
      <div class="global-table no-radius p0">
        <div class="tab-content1">
          <div class="tab-pane1">
            <div class="row">
              <div class="col-md-6">
                <div class="chat-box-main">
                  <div class="chat-box">
                    <div class="chat-head">
                      <h4>Chat</h4>
                    </div>
                    <!-- chat between buyer and seller -->
                    <div [hidden]="takeDipute" class="chat-div" #scrollMe style="overflow-y: auto;">
                      <div *ngFor="let item of chatHistory">
                        <div *ngIf="tradeDetails.fkUserId != item.fromUserId" class="left-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="">
                            <div class="chat">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>

                        </div>
                        <div *ngIf="tradeDetails.fkUserId == item.fromUserId" class="right-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="" style="order: 1;">
                            <div class="chat2" style="order: 0;">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <!-- chat between admin with buyer or seller -->
                    <div [hidden]="!takeDipute" class="chat-div" #scrollMeAdminChat style="overflow-y: auto;">
                      <div *ngFor="let item of supportChatArr">
                        <div *ngIf="item?.toUserId == adminId" class="left-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender">
                            <div class="chat">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{item?.creationTime | date}}</span>
                            </div>
                          </div>

                        </div>

                        <div *ngIf="item?.toUserId != adminId" class="right-chat">
                          <div class="d-flex">
                            <img [src]="item?.profileImageOfBuyer || 'https://dummyimage.com/50x50/234795/fff'"
                              class="profileChat img-circle" alt="sender" style="order: 1;">
                            <div class="chat2">
                              <p *ngIf="item.messageFormat == 'TEXT' && item.message">
                                {{item?.message}}</p>
                              <img class="img_def" target="_blank" [src]="item.message"
                                *ngIf="item.messageFormat == 'IMAGE'" alt="">
                              <span>{{currentDate | date}}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div *ngIf="takeDipute" class="asking-div">
                      <!-- <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Evidence asking</label>
                      </div> -->
                      <!-- if trade type Buy -->
                      <select *ngIf="buyerSeller == 'BUY'" class="form-control" name="" id=""
                        (change)="selectUserForChat($event)">
                        <option value="">Select User</option>
                        <option [value]="tradeDetails?.fkUserId">Buyer {{tradeDetails?.buyer || 'Buyer'}} </option>
                        <option [value]="tradeDetails?.partnerId">Seller {{tradeDetails?.seller || 'Seller'}} </option>
                      </select>
                      <!-- if trade type sell -->
                      <select *ngIf="buyerSeller == 'SELL'" class="form-control" name="" id=""
                        (change)="selectUserForChat($event)">
                        <option value="">Select User</option>
                        <option [value]="tradeDetails?.partnerId">Buyer {{tradeDetails?.buyer || 'Buyer'}}</option>
                        <option [value]="tradeDetails?.fkUserId">Seller {{tradeDetails?.seller || 'Seller'}}</option>
                      </select>

                      <select class="form-control" (change)="timerForEvidenceAsk($event)" [(ngModel)]="timerEvidence">
                        <option value="">Timer</option>
                        <option *ngFor="let item of timerArray" [value]="item">{{item}} Hour</option>
                      </select>
                    </div>

                    <div *ngIf="takeDipute" class="form-group custm-inpt" style="display: flex;">
                      <input type="text" id="usr" class="form-control" name="chat" placeholder="Write something here..."
                        [(ngModel)]="obj.chat" (keyup.enter)="sendChat()" autocomplete="off">
                      <div class="chat-attachment-img-box">
                        <input type="file" class="statusTradeUploadChat" (change)="handleFileInput($event)">
                        <img src="../../../assets/img/attached.png" alt="" class="chat-attachment-img">
                      </div>
                      <button type="button" class="btn btn-theme" style="z-index: 100;"
                        (click)="sendChat()">Send</button>
                    </div>

                  </div>
                </div>
              </div>

              <!-- trade details -->
              <div class="col-md-6">
                <div class="sec_head_new">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Trade Type </label>
                        <div class="col-sm-5">
                          <label class=" col-form-label">{{tradeDetails?.type || '---'}}</label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Advertisement</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradingPartner || '---'}}</label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Ad Creator</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradingPartner || '---'}}</label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Status</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.statusType || '---'}}</label>
                        </div>
                      </div>

                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Amount BTC</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.totalBTC || '---'}} <span
                              *ngIf="tradeDetails?.totalBTC">{{tradeDetails?.cryptoCoin}}</span> </label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">For</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.tradeAmount || '---'}} <span
                              *ngIf="tradeDetails?.tradeAmount">{{tradeDetails?.fiatCoin}}</span> </label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Exchange Rate</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.price || '---'}} <span
                              *ngIf="tradeDetails?.price">{{tradeDetails?.cryptoCoin}}/{{tradeDetails?.fiatCoin}}</span></label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Payment Method</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.paymentType || '---'}}</label>
                        </div>
                      </div>
                      <div class="form-group row trafe-row">
                        <label class="col-sm-6 col-form-label headinds">Staff assigned</label>
                        <div class="col-sm-6">
                          <label class=" col-form-label">{{tradeDetails?.staffName || 'Admin'}}</label>
                        </div>
                      </div>

                      <div *ngIf="tradeDetails?.recommendation" class="row trafe-row">
                        <label class="col-sm-12 col-form-label headinds">Remark</label>
                        <div class="col-md-12">
                          <p class=" mb-0">{{tradeDetails?.recommendation}}</p>
                        </div>
                      </div>

                      <div *ngIf="!takeDipute" class="mt-3 text-center">
                        <button class="take-action" (click)="takeDisputeByAdmin()">Take Dispute</button>
                      </div>


                      <div *ngIf="takeDipute" class="mt-3 text-center">
                        <button type="submit" class="btn btn-action mr-2" (click)="takeActionByAdmin()">Take
                          Action</button>
                        <button type="button" class="btn btn-action" (click)="recommendedActionByAdmin()">Recommended
                          Action</button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- dispute history -->
            <div class="transaction">
              <h3>Dispute History</h3>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered" aria-describedby="Dispute history table">
                <thead>
                  <tr class="no_wrap_th">
                    <th scope="col">Report Id</th>
                    <th scope="col">Date </th>
                    <th scope="col">User</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>

                  <tr
                    *ngFor="let item of disputeHistoryArray | paginate:{itemsPerPage: itemsPerPageDispute, currentPage: currentPageDispute,totalItems: totalItemsDispute, id:'dispute'}">
                    <td>#{{item?.disputeId ? item?.disputeId.slice(-5):''}}</td>
                    <td>{{item?.disputeDate | date:'medium'}}</td>
                    <td>{{item?.buyer}}</td>
                    <td>{{item?.disputeStatus}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="custom-pagination mt20 text-right" *ngIf="totalItemsTrade > 5">
              <pagination-controls (pageChange)="paginationDispute($event)" id="dispute"></pagination-controls>
            </div>

            <div class="transaction">
              <h3>Trade History</h3>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered" aria-describedby="trade history table">
                <thead>
                  <tr class="no_wrap_th">
                    <th scope="col">Report Id</th>
                    <th scope="col">Date </th>
                    <th scope="col">User</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of tradeHistoryArray | paginate:{itemsPerPage: itemsPerPageTrade, currentPage: currentPageTrade,totalItems: totalItemsTrade, id:'trade'}">
                    <td>#{{item?.disputeId ? item?.disputeId.slice(-5):''}}</td>
                    <td>{{item?.disputeDate | date:'medium'}}</td>
                    <td>{{item?.buyer}}</td>
                    <td>{{item?.disputeStatus}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="custom-pagination mt20 text-right" *ngIf="totalItemsTrade > 5">
              <pagination-controls (pageChange)="paginationTrade($event)" id="trade"></pagination-controls>
            </div>
          </div>
          <div class="text-center" style="margin-top: 20px;">
            <button class="btn btn-info" routerLink="/dispute-management">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<!-- remark modal -->
<div class="modal fade global-modal reset-modal" id="recommendedActionModal">
  <div class="modal-dialog max-WT-500">
    <form class="change_password" [formGroup]="remarkForm">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h4 style="text-align: center; ">Remark</h4>
            <hr>
            <button type="button" class="close" data-dismiss="modal" style="margin-top: -85px;">&times;</button>
            <div class="justify-content-center">
              <div class="w-100">

                <div class="hot-coldpopup mb-4">
                  <textarea class="recommended-textarea" rows="4" placeholder="Enter your remark"
                    formControlName="remark"></textarea>
                </div>
                <div class="buttons-alignments d-flex align-items-center justify-content-center mt-4">
                  <button type="submit" class="btn btn-warning mr-4" [disabled]="remarkForm.invalid"
                    (click)="recommendedActionByAdminSave()">Save</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- take decision modal -->
<div class="modal fade global-modal reset-modal" id="takeActionModal">
  <div class="modal-dialog max-WT-500">
    <div class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h4 style="text-align: center; ">Take Decision Trade #{{tradeId.slice(-5)}}</h4>
            <hr>
            <button type="button" class="close" data-dismiss="modal" style="margin-top: -85px;">&times;</button>
            <div class="justify-content-center">
              <div class="w-100">

                <div class="hot-coldpopup mb-4">
                  <div class="form-group release">
                    <button type="button" class="btn btn-green-buy mb-1" (click)="releaseForDetailsModal()">Release For
                      Buyer ({{tradeDetails?.buyer}})</button>
                    <button type="button" class="btn btn-red-sell" (click)="releaseForDetailsModal()">Release For Seller
                      ({{tradeDetails?.seller}})</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- release escrow modal -->
<div class="modal fade global-modal reset-modal" id="releaseEscrowModal">
  <div class="modal-dialog max-WT-500">
    <div class="change_password">
      <div class="modal-content">
        <div>
          <div class="modal-body">
            <h4 style="text-align: center; ">Take Decision Trade #{{tradeId.slice(-5)}}</h4>
            <hr>
            <button type="button" class="close" data-dismiss="modal" style="margin-top: -85px;">&times;</button>
            <div class="justify-content-center">
              <div class="w-100">
                <p>Are you sure for release escrow</p>
                <div class="hot-coldpopup">
                  <label>Trade Amount: {{tradeDetails?.totalBTC || '---'}} {{tradeDetails?.cryptoCoin}}</label>
                </div>
                <div class="hot-coldpopup">
                  <label>Username: {{tradeDetails?.staffName}}</label>
                </div>
                <div class="buttons-alignments d-flex align-items-center justify-content-center mt-4">
                  <button type="submit" class="btn btn-danger mr-4" (click)="releaseForDetails()">Yes</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
