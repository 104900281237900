<div class="wrapper">

    <!-- Aside End -->
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">Change Password</h1>
        </div>
        <div class="content-section">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <form class="login_box_outer" [formGroup]="resetPasswordForm">
                            <div class="login-box-body">
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Old Password"
                                        formControlName="oldPassword" />
                                    <div
                                        *ngIf="resetPasswordForm.controls['oldPassword'].hasError('required') && resetPasswordForm.controls['oldPassword'].touched">
                                        <span class="error">*Old Password is required.</span>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="New Password"
                                        formControlName="password" />
                                    <div
                                        *ngIf="resetPasswordForm.controls['password'].hasError('required') && resetPasswordForm.controls['password'].touched">
                                        <span class="error">*New Password is required.</span>
                                    </div>
                                    <div
                                        *ngIf="resetPasswordForm.controls['password'].hasError('pattern') && (resetPasswordForm.controls['password'].touched || resetPasswordForm.controls['password'].dirty)">
                                        <span class="error"> Password should have minimum 8 characters, at least 1
                                            uppercase letter, 1 lowercase letter, 1 number and 1 special
                                            character.</span>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Confirm New Password"
                                        formControlName="confirmPassword" />
                                    <div>
                                        <span
                                            *ngIf="resetPasswordForm.controls['confirmPassword'].hasError('required') && resetPasswordForm.controls['confirmPassword'].touched"
                                            class="error">*Confirm new password is required.</span>
                                        <span class="error"
                                            *ngIf="resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value && resetPasswordForm.controls['confirmPassword'].dirty">New
                                            password and confirm new password not matched.</span>
                                    </div>
                                </div>

                                <div class="text-center form-group mt40" style="display: flex;">
                                    <button type="submit" class="btn btn-large  max-WT-200 font-100 btn-green"
                                        [disabled]="!resetPasswordForm.valid || (resetPasswordForm.controls['password'].value != resetPasswordForm.controls['confirmPassword'].value)"
                                        (click)="resetPasswordFunc()">Update Password</button>

                                    <button routerLink="/setting" class="btn btn-large  max-WT-200 font-100 btn-green"
                                        style="margin-left: 20px;">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>