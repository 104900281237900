import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { SidemenuComponent } from '../sidemenu/sidemenu.component';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  userDetail: any = [];
  editForm: FormGroup;
  editImage: any;

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    this.editFormValidation();
    this.myProfile();
  }

  editFormValidation() {
    this.editForm = new FormGroup({
      'name': new FormControl('',[Validators.required ,Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'city': new FormControl('',[Validators.required , Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'country': new FormControl('',[Validators.required , Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)),
      'phone': new FormControl('',[Validators.required , Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'address': new FormControl('',[Validators.required ])
    })
  }

  // get admin profile
  myProfile(){
    let url = "admin/getProfile"
    this.service.showSpinner()
    this.service.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.userDetail = res.result
        this.editForm.patchValue({
          name : res.result?.name,
          city : res.result?.city,
          country : res.result?.country,
          email : res.result?.email,
          phone : res.result?.mobileNumber,
          address : res.result?.address
         

        })
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
       this.service.errorToast(res.responseMessage)
       this.service.hideSpinner()
      }
    },(err)=>{
     this.service.errorToast(err)
 
     this.service.hideSpinner()
    })
   }
 

  // upload profile image
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
   
  }

  // update profile
  updateProfile() {
    let url = "admin/editAdmin"
    const data = {
      name : this.editForm.value.name,
      email: this.editForm.value.email,
      mobileNumber: this.editForm.value.phone,
      address: this.editForm.value.address,
    }
    this.service.showSpinner()
    this.service.putApi(url,data,1).subscribe((res)=>{
      if(res.responseCode == 200){
      
        this.service.hideSpinner()
        this.service.successToast(res.responseMessage)
      }
      else{
       this.service.errorToast(res.responseMessage)
       this.service.hideSpinner()
      }
    },(err)=>{
     this.service.errorToast(err)
 
     this.service.hideSpinner()
    })
  }



  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    } else {

    }

  }
}
