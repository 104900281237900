<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:black">Dispute Resolution</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="sec_head_new">
                            <div class="row">
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Dispute Status</span>
                                            <select class="form-control" [(ngModel)]="tradeStatus">
                                                <option value="" placeholder="Select">Select</option>
                                                <option value="Raised">Raised</option>
                                                <option value="WIP">WIP</option>
                                                <option value="Pending_for_Release">Pending For Release</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Country</span>
                                            <select class="form-control" [(ngModel)]="country">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of countryList" [value]="item?.country">
                                                    {{item?.country}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Payment Method</span>
                                            <select class="form-control" [(ngModel)]="paymentType">
                                                <option value="">Select</option>
                                                <option value="NationalBankTransfer">National Bank Transfer</option>
                                                <option value="IMPS">IMPS</option>
                                                <option value="paypal">PAYPAL</option>
                                                <option value="paytm">PAYTM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-5 user-pl" >
                                    <div class="user-frmbtn" style="display: flex;">
                                        <button type="submit" (click)="search()"
                                            class="btn  btn-theme mr-2">Search</button>
                                        <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>
                                        <button type="submit" class="btn btn-theme" style="margin-left: 2%;" (click)="exportAsXLXS()">EXPORT AS EXCEL
                                        </button>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2 user-pl">
                                  <div class="user-frmbtn" >

                                      <button type="submit" (click)="reset()" class="btn  btn-theme">Reset</button>

                                  </div>
                              </div>
                              <div class="col-md-2 user-pl">
                                <div class="user-frmbtn" >

                                    <button type="submit" class="btn btn-theme" (click)="exportAsXLXS()">EXPORT AS EXCEL
                                    </button>
                                </div>
                            </div> -->
                            </div>

                            <!-- next row -->

                            <!-- <div class="col-md-2" style="padding-left: 0px;">
                                    <div class="head_flt_select">
                                        <div class="head_flt_select input-label input-label1">
                                            <span class="d-flex align-items-center">Trade Type</span>
                                            <select class="form-control" [(ngModel)]="type">
                                                <option value="">Select</option>
                                                <option value="SELL">Sell</option>
                                                <option value="BUY">Buy</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                            <!-- <div class="col-md-10 ">
                                <div class="text-left" style="float: right;  ">
                                    <button type="submit" class="btn btn-theme" (click)="exportAsXLXS()" style="  margin-top: -50%;
                                    margin-left: 55%;">Export As
                                        Excel</button>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="transaction"><h3>Trade List</h3></div> -->
                    <div class="table-responsive">
                        <table class="table table-bordered" aria-describedby="trade table">
                            <thead>
                                <tr class="no_wrap_th">
                                    <th id="">Trade ID</th>
                                    <th id="">Dispute ID</th>
                                    <th id="">Buyer</th>
                                    <th id=""> Seller</th>
                                    <th id="">Disputer</th>
                                    <th id="">Trade Date</th>
                                    <th id="">Dispute Date</th>
                                    <th id="">Dispute Status</th>
                                    <th id="">Trade Status</th>
                                    <th id="">Currency Type</th>
                                    <th id="">Transaction Fee</th>
                                    <th id="">Payment Method</th>
                                    <th id="">Staff Incharge</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr
                                        *ngFor="let data of tradeList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems: tradeListlength}; let i=index; "> -->
                                <tr
                                    *ngFor="let data of disputeList | paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage,totalItems: totalItems}; let i=index; ">
                                    <td (click)="navigate(data.tradeId)"><u
                                            class="trade-table-data">{{data?.tradingId || '---'}}</u></td>
                                    <td>
                                      <div *ngIf="data?.disputeId !=null" class="myhash">
                                    {{data?.disputeId  }}<i class="fa fa-clone" aria-hidden="true"  style="cursor: pointer;margin-left: 2%;" (click)="copyToClipboard(data?.disputeId )"></i>
                                  </div>
                                  <div *ngIf="data?.disputeId ==null">
                                    ---
                                  </div>
                                    </td>
                                    <td>{{data.seller || '---'}}</td>
                                    <td>{{data.buyer || '---'}}</td>
                                    <td>
                                     {{data.disputer || '---'}}
                                    </td>
                                    <td>{{(data?.creationTime | date:'medium') || '---'}}</td>
                                    <td>{{(data?.disputeDate | date:'medium') || '---'}}</td>
                                    <td>{{data?.disputeStatus || '---'}}</td>
                                    <td>{{data?.tradeStatus || '---'}}</td>
                                    <td>{{data?.tradeAmount}} {{data?.fiatCoin}}</td>
                                    <td>{{data?.tradeFee}}</td>
                                    <td>{{data?.paymentType || '---'}}</td>
                                    <td><span class="trade-table-data"
                                            (click)="assignStaffModalOpen(data?.disputeId, data?.staffId)"><u>{{data?.staffName || 'Unassigned'}}</u></span>
                                    </td>
                                </tr>
                                <tr *ngIf="disputeList?.length==0">
                                    <td colspan="13" vertical-align="middle">
                                        <div class="no-record">
                                            <div class="no-recordin">
                                                <h5>No record found</h5>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="custom-pagination mt20 text-center" style="float: right;"
                            *ngIf="tradeListlength > 5 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div> -->
                    <!-- <div class="custom-pagination mt20 text-center" style="float: right;" *ngIf="totalItems > 5 ">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div> -->
                    <div class="custom-pagination mt20 text-center" style="float: right;">
                        <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                    </div>
                </div>

            </div>
        </div>

    </div>

</main>

<div class="modal fade global-modal reset-modal" id="assignStaffModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="assignStaffForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h4 style="text-align: center; ">Assign Dispute</h4>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <!-- <div class="text-center  d-flex align-items-center justify-content-center"> -->
                        <div class="justify-content-center">
                            <div class="w-100">
                                <br>
                                <!-- <div class="hot-coldpopup mb-4">
                                    <label>Staff Name: </label>
                                    <select class="form-control" formControlName="staffName" style="width: 68%;">
                                        <option value="">Select Staff</option>
                                        <option value="ajay">Ajay</option>
                                    </select>

                                </div>
                                <div class="text-danger"
                                *ngIf="assignStaffForm.get('staffName').invalid && (assignStaffForm.get('staffName').dirty  || assignStaffForm.get('staffName').touched)">
                                <span *ngIf="assignStaffForm.get('staffName').hasError('required')">*Please
                                    select
                                    staff name.</span>
                            </div> -->
                                <div class="row">
                                    <div class="col-md-3" style="margin-top: 10px;"> <label>Staff Name: </label>
                                    </div>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="staffName">
                                            <option value="">Select Staff</option>
                                            <option *ngFor="let item of staffListArray" [value]="item.userId">
                                                {{item?.firstName + ' ' + item?.lastName}}</option>
                                        </select>

                                        <div class="text-danger"
                                            *ngIf="assignStaffForm.get('staffName').invalid && (assignStaffForm.get('staffName').dirty  || assignStaffForm.get('staffName').touched)">
                                            <span *ngIf="assignStaffForm.get('staffName').hasError('required')">*Please
                                                select
                                                staff name.</span>
                                        </div>
                                    </div>

                                </div>

                                <div class="buttons-alignments d-flex align-items-center justify-content-center mt-4">
                                    <button type="submit" class="btn btn-warning mr-4"
                                        [disabled]="assignStaffForm.invalid" (click)="assignStaff()">Assign</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
