import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {
  userId: any;
  editAdminForm: FormGroup;
  ipAddress: any;
  newArr: any = [];
  adminDetails: any
  adminPrivileges: any;

 

  constructor(public param: ActivatedRoute, public service: MainService, public router: Router, private http: HttpClient) {
   
  
   
  }

  ngOnInit() {
    this.editAdminForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      'lastName': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'role': new FormControl('', Validators.required),
      'gender': new FormControl('', Validators.required),
      'phoneNumber': new FormControl('', Validators.required),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      userChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl('')
    })
    this.getProfile()
  }

  getProfile() {
    
   
  }


  addStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editAdminForm.value.email,
      "firstName": this.editAdminForm.value.firstName,
      "gender": this.editAdminForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editAdminForm.value.lastName,
      "location": "string",
      "phoneNo": this.editAdminForm.value.phoneNumber,
      "previlage": this.newArr,
      // "roleStatus": "ADMIN",
      "roleStatus": this.editAdminForm.value.role,
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.userId
    }
  
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    console.log("permission->", value, checked)
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      console.log(index)
      this.newArr.splice(index, 1)
      console.log("new arr->", this.newArr);
    }
  }

  cancel() {
    this.router.navigate(['/admin-management'])
  }

}
