

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
    selector: 'app-fee-management',
    templateUrl: './fee-management.component.html',
    styleUrls: ['./fee-management.component.css']
})
export class FeeManagementComponent implements OnInit {
    pageNumber: number = 1;
    privilegePrice: any;
    twoDate: any;
    currencycoin: any = "BTC"
    allcoin = "BTC";
    Hggcoin: any;
    show: boolean = false;
    time: any;
    profitobj: any = {}
    walletCheckFee: any = []
    type = "WITHDRAW";
    optionFee: any = 'WITHDRAW';
    currTab: any;
    numRegxForDot = (/^\d{0,6}(\.\d{1,6})?$/);

    btcdata: any = {}
    calender: any = { todate: '', formdate: '' }
    minAge: Date;

    feecoinArry: any = []
    mininArry: any = []
    btcFeeVali: any;
    fromDate: any;
    coinlist: any = [];
    coinListArr: any = [];
    loopdata: any = [];
    manageCoins: boolean = true;
    manageTrading: boolean = true;
    particularData: any = [];
    editedCoinName: any;
    manageCoinForm: FormGroup;
    manageCoinPair: FormGroup;
    particularDatad: any = [];
    coinpairlistarray: any = [];
    baseCoinn: any;
    executableCoinn: any;
    visiblen: boolean;
    constructor(private router: Router, public service: MainService) { }



    ngOnInit() {
        this.manageCoinForm = new FormGroup({
            isVisible: new FormControl(''),
            remark: new FormControl(''),
            isWithdrawl: new FormControl(''),
            isDeposite: new FormControl('')
        });
        this.manageCoinPair = new FormGroup({
            pairedCoin: new FormControl(''),
            visible: new FormControl(''),
            baseCoin: new FormControl('')
        })
        this.defaults();
        this.getCoinWalletList();
        // this.getcoinPairlist();
        var today = new Date();
        var minAge = 0;
        this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    }

    getcoinPairlist() {
       
    }

    getCoinWalletList() {
       // this.service.showSpinner();
       
    }
    // http://182.72.203.244:3062/wallet/admin/set-coin-status?coin=BTC

    updateMnageCoins() {
        // this.service.showSpinner();
        let data = {
            "coinName": this.editedCoinName,
            "confermation": 0,
            "isDeposite": this.manageCoinForm.value.isDeposite,
            "isVisible": this.manageCoinForm.value.isVisible,
            "isWithdrawl": this.manageCoinForm.value.isWithdrawl,
            "remark": this.manageCoinForm.value.remark
        }
      
    }
    updateCoinPairs() {
        let data = {
            "baseCoin": this.baseCoinn,
            "executableCoin": this.executableCoinn,
            "visible": this.manageCoinPair.value.visible
        }
        console.log("ysugfdi89sdyfjksdghf8sydifgkjhbg", data)
      
    }

    editStaff(element) {
        this.editedCoinName = element;
        this.manageCoins = false;
        let data = {
            "coinName": "string",
            "confermation": 0,
            "isDeposite": true,
            "isVisible": true,
            "isWithdrawl": true,
            "remark": "string"
        }
       
    }

    manageCoinsss() {
        this.manageTrading = true;
        this.getCoinWalletList();
        this.getcoinPairlist();
    }

    editStafff(baseCoin, executableCoin, visible) {
        this.manageTrading = false;
        this.baseCoinn = baseCoin;
        this.executableCoinn = executableCoin;
        this.visiblen = visible
        console.log("visiblen", visible, this.visiblen)
        let data = {
            "baseCoin": this.manageCoinPair.value.baseCoin,
            "executableCoin": this.manageCoinPair.value.executableCoin,
            "visible": this.manageCoinPair.value.visible
        }
        //   this.service.get('wallet/coin/Set-coinPair-visibility'+(data)).subscribe((res:any)=>{
        //     console.log("jhgds87jgdes",res)
        //     if(res.status == 200) {
        //         // this.loopdata.push(res.data);
        //         this.particularData = res.data;
        //         this.service.hideSpinner();
        //     }
        //     console.log("hgfsdi76t5skughjvdxksu786dkxhsjmxg",this.loopdata);
        //     // else {
        //     //     this.service.hideSpinner();
        //     // }
        // },(error)=>{
        //     console.log("ghjf786d",error);

        //     this.service.hideSpinner();
        // })
    }

    getCoinStatus() {
       
    }

    manageCoinss() {
        this.manageCoins = true;
    }

    // --------------- default selected tab ----------------- //
    defaults() {
        this.currTab = 'Fee';
        this.getCoinList()
    }

    // todate() {
    //     this.twoDate = new Date(this.calender.todate)
    //     this.twoDate = this.twoDate.getTime()
    // }

    // formdate() {
    //     this.fromDate = new Date(this.calender.formdate)
    //     this.fromDate = this.fromDate.getTime()
    // }

    // -------------- switch between tabs -------------- */
    selectTab(tab) {
        this.currTab = tab;
        if (this.currTab == 'Fee') {
            this.getCoinList();
        } else if (this.currTab = 'Advertisement') {
            this.getCoinList()
        }
    }

    // coinfunction(coin) {
    //     this.currencycoin = coin
    // }

    // demo(val) {
    //     this.optionFee = val
    //     this.show = false;
    // }

    // ---------------- get coin list ----------------- //
    getCoinList() {
       
    }

    // --------- update withdraw fee --------------- //
    updatefeeapi(coinShortName, withdraw) {
      
    }

    
    // ------------- update trade fee ---------------- //
    updateTradeFee(coin, tradeFee) {
       

    }

}


