import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

declare var $:any

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  userDetail: any = [];

  constructor(
    public service : MainService, public router: Router
  ) { }

  ngOnInit() {
    this.myProfile();
  }

  myProfile(){
   let url = "admin/getProfile"
   this.service.showSpinner()
   this.service.getApi(url,1).subscribe((res)=>{
     if(res.responseCode == 200){
       this.userDetail = res.result
       this.service.hideSpinner()
       this.service.successToast(res.responseMessage)
     }
     else{
      this.service.errorToast(res.responseMessage)
      this.service.hideSpinner()
     }
   },(err)=>{
    this.service.errorToast(err)

    this.service.hideSpinner()
   })
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
