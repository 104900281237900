import { Component, OnInit } from '@angular/core';
// import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl } from '@angular/forms';

declare var $: any

@Component({
  selector: 'app-referral-setting',
  templateUrl: './referral-setting.component.html',
  styleUrls: ['./referral-setting.component.css']
})
export class ReferralSettingComponent implements OnInit {

  userDetail: any = {};
  referralForm: FormGroup;
  referralData: any;
  refToggle: boolean = false;
  referralSettingId: any;

  constructor(
    public service: MainService, public router: Router
  ) { }

  ngOnInit() {
    this.referralFormValidation();
    this.getReferralAmount();
    // this.referralEnableDisable()
    // this.getReferralSetting();
  }

  referralFormValidation() {
    this.referralForm = new FormGroup({
      'amount': new FormControl('')
    })
  }

  getReferralAmount() {
    let url = 'account/admin/user-management/get-referral-amount'
    
  }
  //   message: "not data found"
  // status: 205
  // timestamp: "2021-03-13T12:27:17.664+0000"

  // account/admin/user-management/enable-referral
  referralEnableDisable() {
    let apiReqData = {
      "isEnable": this.refToggle =! this.refToggle,
      "referralSettingId": this.referralSettingId
    }
    
  }


  getReferralSetting() {
    let apiReqData = {
      "amount": this.referralForm.value.amount,
      "referralSettingId": this.referralSettingId
    }
   
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}
