
<div class="wrapper">
    <main class="middle-content">
      <div class="page_title_block">
        <h1 class="page_title"> Edit FAQ's</h1>
        <hr style="background: black; opacity: 0.5;">
      </div>
  
      <div class="content-section">
        <div class="order-view max-WT setting-page">
          <form [formGroup]="form" (input)="inputFunct()">
            <div class="mb40">
              <div class="add-store-block input-style mb20 ">
                <div class="form-group row  view-label-align">
                  <label class="col-md-3 textalignright ">Question
                    <span>:</span></label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" maxlength="100" formControlName="question">
                    <div
                      *ngIf="form.get('question').invalid && (form.get('question').dirty || form.get('question').touched)"
                      class="text-danger">
                      <span *ngIf="form.get('question').hasError('required')"> *Please enter question.</span>
                      <span *ngIf="form.get('question').hasError('pattern')"> *Please enter valid question.</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-3 textalignright ">Answer
                    <span>:</span></label>
                  <div class="col-md-9">
                    <textarea class="form-control" formControlName="answer" name="" id="" cols="30" rows="5"
                      (keypress)="mainService.preventSpace($event)"></textarea>
  
  
                    <div *ngIf="form.get('answer').invalid && (form.get('answer').dirty || form.get('answer').touched)"
                      class="text-danger">
  
                      <span *ngIf="form.get('answer').hasError('required')"> *Please enter answer.</span>
                    </div>
  
  
                  </div>
                </div>
              </div>
              <div class="text-center mt40 mb40">
                <button class="btn btn-large  max-WT-150 btn-secondary" routerLink="/faq">Back</button>
                <button class="btn btn-large  max-WT-150 btn-primary ml-2" [disabled]="!updateButton"
                  (click)="updateFaq()">Update</button>
              </div>
              <!-- <div class="text-center mt40 mb40">
                  <button class="btn btn-large  max-WT-150 btn-theme" (click)="editFaq()"
                    [disabled]="!form.valid" style="margin-top: -21%; margin-left: 26%;" >Save</button>
                </div> -->
            </div>
          </form>
        </div>
      </div>
    </main>
  </div> 