import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.css']
})
export class ViewFaqComponent implements OnInit {

  faqList: any;
  faqId: any;
  faqView : any = []

  constructor(public mainService: MainService, private activatedroute: ActivatedRoute) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.id;
    })
    console.log(this.faqId);

  }

  ngOnInit() {

    this.getFaq()
  }

  getFaq(){
    let url = "faq/faqs/"+this.faqId

    this.mainService.showSpinner()
    this.mainService.getApi(url,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.faqView = res.data
        this.mainService.hideSpinner()
        this.mainService.successToast(res.responseMessage)
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    })
   }

}
