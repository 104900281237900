import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
declare var $
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  searchForm: FormGroup;
  // whichmodal: string = 'enable';
  itemPerPage = 5;
  currentPage = 1;
  total: any;
  helplineId: any
  faqList: any = [];
  faqId: any;

  constructor(private router: Router,
    public mainService: MainService) { }
  ngOnInit() {
    // this.searchFormValidation();
    this.getAllFaq()
  }

 

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getAllFaq()
  }

  // ------- get helpline number list -------- //
 getAllFaq(){
   let url = "faq/faqsList"
   const  data = {
     'page': this.currentPage,
     'limit' : this.itemPerPage
   }
   this.mainService.showSpinner()
   this.mainService.postApi(url,data,0).subscribe((res)=>{
     if(res.responseCode == 200){
       this.faqList = res.result.docs
      this.total = res.result.total
       this.mainService.hideSpinner()
       this.mainService.successToast(res.responseMessage)
     }
     else{
        this.mainService.hideSpinner()
       this.mainService.errorToast(res.responseMessage)
     }
   },(err)=>{
    this.mainService.hideSpinner()
    this.mainService.errorToast(err)
   })
 }


  addFaq() {
    this.router.navigate(['/add-faq'])
  }
  viewUser(id) {
    this.router.navigate(['view-faq'], { queryParams: { id: id } })
  }
  editFaq(id) {
    this.router.navigate(['/edit-faq'], { queryParams: { id: id } })

  }
  deleteFaqModal(id) {
    this.faqId = id
    $('#delete').modal('show')
  }

  deleteFaq() {
    let url = "faq/deleteFaqs"
    const data ={
      faqId : this.faqId
    }
    this.mainService.showSpinner()
    this.mainService.deleteApi(url,data,1).subscribe((res)=>{
      if(res.responseCode == 200){
        this.mainService.hideSpinner()
        this.mainService.successToast(res.responseMessage)
        $('#delete').modal('hide')
        this.getAllFaq()
      }
      else{
        this.mainService.hideSpinner()
        this.mainService.errorToast(res.responseMessage)
      }
    })
  }
 hideModal(){
  $('#delete').modal('hide')
 }


}
