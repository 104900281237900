import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
// import { ServiceService } from '../service.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-trade-management',
  templateUrl: './trade-management.component.html',
  styleUrls: ['./trade-management.component.css']
})
export class TradeManagementComponent implements OnInit {
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', formdate: '' }
  minAge: Date;
  tradeStatus: any = ''
  country: any = ''
  paymentType: any = '';
  type: any = '';
  tradeList: any = [];
  itemsPerPage: number = 15;
  currentPage: number = 1
  totalItems: number;
  countryList: any = [];


  constructor(private router: Router, public service: MainService,private datePipe: DatePipe) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
    this.getTradeList()
    this.getCountryList()
  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // get trade list
  getTradeList() {
 
   
  }

  // search
  search() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.getTradeList()
    } else {
      console.log("Please select field for search.", this.tradeStatus)
    }
  }

  // reset search
  reset() {
    if (this.tradeStatus || this.country || this.paymentType || this.type || (this.twoDate || this.fromDate)) {
      this.tradeStatus = ''
      this.country = ''
      this.paymentType = '';
      this.type = '';
      this.calender = { todate: '', formdate: '' }
      this.twoDate = ''
      this.fromDate = ''
      this.getTradeList()
    }
  }

  // pagination
  pagination(page) {
    this.currentPage = page;
    console.log(this.currentPage)
    this.getTradeList()
  }

  // get country list
  getCountryList() {
    
  }

  // nevigate to trade details
  navigate(tradeId) {
    this.router.navigate(['/trade-details', tradeId])
  }
  exportAsXLSX() {
    let dataArr = [];
    console.log('Hello Excel');

    this.tradeList.forEach((element, ind) => {
      dataArr.push({

        'Trade ID': element.tradingId ? element.tradingId : 'N/A',
        'Buyer': element.buyer ? element.buyer  : 'N/A',
        'Seller': element.seller ? element.seller  : 'N/A',

        'Trade Date & Time': element.creationTime ? element.creationTime.slice(0, 10) : 'N/A',
        'Status': element.tradeStatus ?element.tradeStatus  : 'N/A',
        'Trade': element.type ? element.type : 'N/A',
        'Payment Method': element.paymentType ? element.paymentType : 'N/A',
         'Currency Type': element.tradeAmount ? element.tradeAmount : 'N/A',
        "Transaction Fee": element.tradeFee+" " +element.fiatCoin ? element.tradeFee+" "+element.fiatCoin : 'N/A',
        'Exchange': element.totalBTC ? element.totalBTC : 'N/A',

      })
    })
    console.log(dataArr);

    this.service.exportAsExcelFile(dataArr, 'Trade Management list');
  }

}
