import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
// import { ServiceService } from '../service.service';

@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.css']
})
export class TradeDetailsComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef
  tradeId: any;
  tradeDetails: any;
  chatHistory: any = [];

  itemsPerPage: number = 5;
  currentPage: number = 1
  totalItems: number;

  tradeList: any = [];
  fkUserId: any;
  partnerId: any;
  
  constructor(private activatedRoute: ActivatedRoute, private router: Router, public service: MainService) {
    this.activatedRoute.params.subscribe((res) => {
      console.log(res);
      if (res.id) {
        this.tradeId = res.id
      }
    })
  }

  ngOnInit() {
    this.getTradeDetails();
    this.getTradeList()
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
      } catch (err) { }
    }, 100)
  }

  getTradeDetails() {
    this.service.showSpinner();
   















  }

  getChatHistory() {
    
  }

  openDispute() {
   
  }

   // get trade history list 
   getTradeList() {
    
  }

   // pagination
   pagination(page) {
    this.currentPage = page;
    console.log(this.currentPage)
    this.getTradeList()
  }
}
